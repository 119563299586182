export default interface Dashboard
{
        qtdCastingsAbertos: number,
        qtdjobsAPagar: number,
        qtdJobs: number,
        qtdPagos: number,
        qtdProspectados:number;

        segundaProjetada: number, 
        segundaConsolidada :number, 
        tercaProjetada :number, 
        tercaConsolidada :number, 
        quartaProjetada :number, 
        quartaConsolidada:number, 
        quintaProjetada :number, 
        quintaConsolidada:number, 
        sextaProjetada :number, 
        sextaConsolidada :number,  
        sabadoProjetada :number, 
        sabadoConsolidada :number, 
        domingoProjetada :number,  
        domingoConsolidada :number, 
                
        valorProjetadoMes :number, 
        valorConsolidadoMes :number, 
                
        janeiroProjetado :number,  
        janeiroConsolidado :number, 
        fevereiroProjetado :number, 
        fevereiroConsolidado: number, 
        marcoProjetado :number, 
        marcoConsolidado :number, 
        abrilProjetado :number, 
        abrilConsolidado :number,  
        maioProjetado :number, 
        maioConsolidado :number, 
        junhoProjetado :number, 
        junhoConsolidado :number, 
        julhoProjetado :number, 
        julhoConsolidado :number, 
        agostoProjetado :number, 
        agostoConsolidado :number, 
        setembroProjetado :number, 
        setembroConsolidado :number, 
        outubroProjetado :number, 
        outubroConsolidado :number, 
        novembroProjetado :number, 
        novembroConsolidado :number, 
        dezembroProjetado :number, 
        dezembroConsolidado:number,
        
        listaMetas: MetasDashboard[],
        totalClientesSemContato: number,
        totalProspectadosSemContato: number        

}

export enum ModoExibicaoDashboard
{    
    semanal = 1,
    mensal  = 2,
    anual   = 3
}

export interface MetasDashboard
{
    idMeta: number,
    tipoMeta: string,
    periodoMeta: string,
    valorMeta: string,
    valorAlcancado: number,
    porcentagemAlcancada: number
}
