export enum TipoJob
{
        VT = 'VT',
        Spot = 'Spot',
        AudioDescricao = 'AudioDescricao',
        VoiceOver = '"VoiceOver',
        Dublagem = 'Dublagem',
        ELearning = 'ELearning',
        VozApp = 'VozApp',
        Jingle = 'Jingle',
        Institucional = 'Institucional',
        Treinamento = 'Treinamento',
        VideosInternet = 'VideosInternet',
        Vinheta = 'Vinheta',
        Documentarios = 'Documentarios',
        IA = 'IA',
        Manifesto = 'Manifesto',
        URA = 'Ura',
        TTS = 'TTS',
        DublagemComSync = 'DublagemComSync',
        Audiobook = 'Audiobook',
        Podcast = 'Podcast',
        VideoCase = 'VideoCase',
        MaterialInterno = 'MaterialInterno',
        MonstroCasting = 'MonstroCasting',
        Animatic = 'Animatic',        
        Todos = 'Todos'
}