import ResponseResult from "../types/ResponseResult";
import { BaseService } from "./BaseService";

export default class NotificacaoService extends BaseService
{
    constructor() {
        super();
        
        this.baseUrl = "/Notificacao/"
        this.metodoObterNotificacoesPaginada = this.baseUrl + "ObterNotificacoesPaginada"
        this.metodoObterUltimaNotificacao = this.baseUrl + "ObterUltimaNotificacao"
    }

    private baseUrl: string;
    private metodoObterNotificacoesPaginada : string;
    private metodoObterUltimaNotificacao: string;

    ObterPaginado = async(qtdpaginasSkip: number = 0, 
                          qtdPorPagina: number = 10, 
                         ) => {

     let params = {
        SkipPaginas: qtdpaginasSkip,
        QtdPorPagina: qtdPorPagina,
     }       
       let resultado:ResponseResult = await this.GetData(this.metodoObterNotificacoesPaginada, params, true, null)
       return resultado;
    }

    ObterUltimaNotificacao = async() => 
    { 
        console.log(this.metodoObterUltimaNotificacao)
        let resultado: ResponseResult = await this.GetData(this.metodoObterUltimaNotificacao, null, true, null)
        return resultado;
    }
}