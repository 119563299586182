import { useState } from 'react';
import FileSaver from 'file-saver';
import { Spinner } from 'reactstrap';
import ResponseResult from '../../types/ResponseResult';

import './BaseReport.css';

const BaseReport = (param:  {nome:string, 
                             obterRelatorioPdf:() => Promise<ResponseResult>,
                             obterRelatorioExcel:() => Promise<ResponseResult>
                            }) => {
        
    const[RelatorioPdfTela, SetRelatorioPdfTela]         = useState<any>(''); 
    const[loadingPdfTela, setLoadingPdfTela]             = useState<boolean>(false);    
    const[loadingExcelDownload, setLoadingExcelDownload] = useState<boolean>(false);
    const[exibeBotoesDownload, setExibeBotoesDownload]   = useState<boolean>(false);    
        
    const GerarRelatorio = async () => {

        setLoadingPdfTela(true)
        
        let response = await param.obterRelatorioPdf();   
        
        if(response.status === 200)
        {            
            if(verifyEhMobile())
            {
                setExibeBotoesDownload(false)            
                DownloadPdf()    
                setLoadingPdfTela(false)                                         
                return;
            }
            else
            {
                setExibeBotoesDownload(true)
                let pdf = URL.createObjectURL(b64toBlob(String(response.data), "application/pdf")) + " #toolbar=0&navpanes=0&scrollbar=0&view=FitH"        
                SetRelatorioPdfTela(pdf);
            }
        }
         
        setLoadingPdfTela(false)
    }

    const DownloadPdf = async () => {

        let blob: Blob;                   
        let response = await param.obterRelatorioPdf();

        if(response.status === 200)        
          blob = b64toBlob(String(response.data), "application/pdf")                
        else
            return;
                 
        const file = new File([blob!],  param.nome, {type: "application/pdf"})
        FileSaver.saveAs(file)
    }

    const DownloadExcel = async () => {
        setLoadingExcelDownload(true)

        let response = await param.obterRelatorioExcel();  

        setLoadingExcelDownload(false)
        
        if(response.status !== 200)
           return;
        
        const blob = b64toBlob(String(response.data), "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        const file = new File([blob],  param.nome, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
        FileSaver.saveAs(file)
    }

    const b64toBlob = (b64Data:any, contentType:any): Blob => {
        
        var byteCharacters = atob(b64Data)

        var byteArrays = []

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                var slice = byteCharacters.slice(offset, offset + 512),
                    byteNumbers = new Array(slice.length)
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i)
                }
                var byteArray = new Uint8Array(byteNumbers)

                byteArrays.push(byteArray)
            }

            var blob = new Blob(byteArrays, { type: contentType })
            return blob
    }

    const verifyEhMobile = (): boolean => {
        var x = window.matchMedia("(max-width: 500px)")
        return x.matches         
    }

    return(
        <>
         <div className='row corpoReport'>
            <div className='col-lg-12'>
                <div className='card shadow-sm'>
                    <div className="card-header">{param.nome}</div>
                       <div className="card-body">
                            <div className='form-row'>
                                <div className='form-group col-lg-2'>
                                    { loadingPdfTela ? <button type='button' 
                                                     className='btn btn-danger'
                                                     disabled={true}                                                     
                                                >Processando...<Spinner color="info"/>
                                                </button> 
                                            :<button type='button' 
                                                    className='btn btn-danger'
                                                    onClick={GerarRelatorio}                                                    
                                             > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                                   <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                                   <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
                                              </svg> Gerar pdf
                                            </button>                                      
                                    }
                                    
                                </div>                                
                                <div className='form-group col-lg-2 MobileExcel'>
                                    { loadingExcelDownload ? <button type='button' 
                                                     className='btn btn-success'
                                                     disabled={true}                                                     
                                                >Processando...<Spinner color="info"/>
                                                </button> 
                                            :<button type='button' 
                                                    className='btn btn-success'
                                                    onClick={DownloadExcel}                                                    
                                             ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-excel" viewBox="0 0 16 16">
                                                                <path d="M5.18 4.616a.5.5 0 0 1 .704.064L8 7.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 8l2.233 2.68a.5.5 0 0 1-.768.64L8 8.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 8 5.116 5.32a.5.5 0 0 1 .064-.704z"></path>
                                                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"></path>
                                                         </svg> Gerar excel 
                                             </button>                                      
                                    }
                                    
                                </div>
                            </div>
                            
                        {exibeBotoesDownload && <>
                                                  <div className='form-row'>
                                                    <div className='form-group col-lg-2'>
                                                        <button type='button' 
                                                                className='btn btn-warning'
                                                                onClick={DownloadPdf}                                                 
                                                        ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                                                 <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                                                 <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
                                                        </svg>
                                                             Download Pdf
                                                        </button>                                                                                                                             
                                                    </div>                                                    
                                                    <div className='form-group col-lg-2'>
                                                        <button type='button' 
                                                                className='btn btn-success'
                                                                onClick={DownloadExcel}                                                    
                                                        ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-excel" viewBox="0 0 16 16">
                                                              <path d="M5.18 4.616a.5.5 0 0 1 .704.064L8 7.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 8l2.233 2.68a.5.5 0 0 1-.768.64L8 8.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 8 5.116 5.32a.5.5 0 0 1 .064-.704z"></path>
                                                              <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"></path>
                                                         </svg>
                                                            Download Excel
                                                        </button>  
                                                    </div>
                                                </div>
                                                <div className="row">
                                                        <div className='col-lg-12'>
                                                            <embed
                                                                style={{width:'100%', height:'800px'}}  
                                                                type="application/pdf"
                                                                src={RelatorioPdfTela}                                                             
                                                            ></embed>
                                                        </div>
                                                </div>
                                             </>
                        }
                    </div>
                </div>
            </div>
        </div>                                                                                                                                                             
        </>
    )
}

export default BaseReport;