/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import {FormEvent, useEffect, useState} from 'react';

const Paginacao = (paginacao: { 
                                totalRegistros: number | any; 
                                qtdPorPagina: number | any; 
                                qtdPaginasRodape: number | any; 
                                buscarRegistrosPaginados: any;
                                reconstruirComponente: number | any;
                             }) => {
     
    const {totalRegistros, 
           qtdPorPagina, 
           qtdPaginasRodape, 
           buscarRegistrosPaginados,
           reconstruirComponente} = paginacao;
        
    const [paginaAnterior, setBotaoAnterior]            = useState(false);
    const [paginaProxima, setBotaoProximo]              = useState(false);    
    const [arrayPaginasRodape, setarrayPaginasRodape]   = useState<number[]>([]);    
    const [paginaAtual, setPaginaAtual]                 = useState<number>(0);

   useEffect(() => {
      atualizarRodapePaginacao(paginaAtual, false,false,null);
      setPaginaAtual(1);
   }, [totalRegistros, reconstruirComponente])

    const setProximaPaginaAtual = () => {                         
        
        let proximaPagina = paginaAtual + 1;       
        setPaginaAtual(proximaPagina);          
    }

    const setPaginaAnterior = () => {   
        
        let paginaAnterior = paginaAtual - 1;        
        setPaginaAtual(paginaAnterior);
    }

    const handleClickPagina = async(pagina:number, event: FormEvent) => {
         
          event.preventDefault();  
          setPaginaAtual(pagina);
        
          setBotaoAnterior(pagina > 1)  
          
          calculaHabilitacaoBotaoProximo(pagina - 1);

          await buscarProximaEtapaDeRegistros(pagina);                            
    }

    const buscarProximaEtapaDeRegistros = async (paginaAtual:number) => {    

        if(paginaAtual !== undefined){
            let proximoRegistro  = (paginaAtual - 1 ) * qtdPorPagina <= 0 
            ? 0 
            : 
           (paginaAtual - 1 ) * qtdPorPagina;

           await buscarRegistrosPaginados(proximoRegistro);
        }                                
    }
    
    const atualizarRodapePaginacao = async(paginaAtual:number, anterior:boolean, proximo: boolean, event: FormEvent | any) => {
        
        if(event)
           event.preventDefault();
            
        if(totalRegistros === 0) 
           return ("");
        else
        {                      
            let arrayTodasPaginasRodape:number[]  = obterArrayTodasPaginasRodape();
 
            //Lógica para exibição das páginas no rodapé da paginação                                
            if(anterior || proximo)
            {
              await construirArrayPaginasRodapeBotaoAnteriorEProximo(paginaAtual, anterior, proximo, qtdPaginasRodape, arrayTodasPaginasRodape);
            }
            else 
              await construirArrayPaginasRodapePadrao(qtdPaginasRodape, arrayTodasPaginasRodape);
        }            
    }

    const obterArrayTodasPaginasRodape = () => {
        
        let totalPaginas                = totalRegistros <= qtdPorPagina ? 1 : Math.ceil(totalRegistros / qtdPorPagina);            
            
        let arrayTodasPaginas:number[]  = [];
        let numeroPagina = 1;
        
        while (numeroPagina <= totalPaginas) {
            arrayTodasPaginas.push(numeroPagina);

            numeroPagina++;
        }

        return arrayTodasPaginas;
    }

    const construirArrayPaginasRodapeBotaoAnteriorEProximo = async(paginaAtual: number,
                                                                   anterior:boolean, 
                                                                   proximo: boolean, 
                                                                   qtdPaginasPorPesquisa:number,
                                                                   arrayTodasPaginasRodape:number[]) => {
        if(anterior)
           await executarFuncaoBotaoAnterior(paginaAtual, qtdPaginasPorPesquisa, arrayTodasPaginasRodape);
        else          
        if(proximo)
           await executarFuncaoBotaoProximo(paginaAtual,qtdPaginasPorPesquisa, arrayTodasPaginasRodape);              
    }

    const calculaHabilitacaoBotaoProximo = (paginaAtual: number) => {

      let arrayTodasPaginasRodape:number[]  = obterArrayTodasPaginasRodape();
          
      let ultimoItemArrayCorrente  = arrayPaginasRodape[arrayPaginasRodape.length - 1];//Pega a ultima página do range corrente         

      let ultimaPaginaTodosArrays = arrayTodasPaginasRodape.length;

      if(paginaAtual + 1 === ultimoItemArrayCorrente)            
        {
            if(ultimaPaginaTodosArrays > paginaAtual + 1)
            {               
               setBotaoProximo(true);               
            }
            else
            {
               setBotaoProximo(false);
            }                                         
        }
        else
        if(paginaAtual === ultimoItemArrayCorrente)
        {
            if(ultimaPaginaTodosArrays > paginaAtual)
            {
                setProximaPaginaAtual();
                setBotaoProximo(true); 

                let proximoUltimoItemArray = ultimoItemArrayCorrente + qtdPaginasRodape;

                if(proximoUltimoItemArray >= arrayTodasPaginasRodape[arrayTodasPaginasRodape.length - 1])                
                   setBotaoProximo(false);  
                else
                {                
                   setBotaoProximo(true);
                }                                                                 
            }
            else
            {
               setBotaoProximo(false);                              
            }
        }
        else{
            setBotaoProximo(true);
        }

    }

    const executarFuncaoBotaoAnterior = async (paginaAtual: number,
                                               qtdPaginasPorPesquisa:number,
                                               arrayTodasPaginasRodape:number[]) => {

        let arrayPaginasParaExibir:number[]  = [];                          
        let paginaAtualizada                 = paginaAtual - 1;

        if(paginaAtualizada === 1)
           setBotaoAnterior(false);
        else
           setBotaoAnterior(true);

        let primeiraPaginaArrayCorrente = arrayPaginasRodape[0];

            if(primeiraPaginaArrayCorrente > qtdPaginasPorPesquisa && primeiraPaginaArrayCorrente === paginaAtual)
            {
                let ultimaPosicaoProximoArray    = primeiraPaginaArrayCorrente - 1;
                let posicaoInicialNova           = ultimaPosicaoProximoArray - qtdPaginasPorPesquisa;
                let posicaoFinalNova             = ultimaPosicaoProximoArray;            
                 
                arrayPaginasParaExibir       = arrayTodasPaginasRodape.slice(posicaoInicialNova, posicaoFinalNova);                                 
                    
                    if(paginaAtualizada === 1) {
                        setBotaoAnterior(false);
                    }
                    else
                        setBotaoAnterior(true);

                setPaginaAnterior();
            }
            else
            {
               setPaginaAnterior();
               arrayPaginasParaExibir = arrayPaginasRodape;
            }
                         
             setBotaoProximo(true);             
             setarrayPaginasRodape(arrayPaginasParaExibir);                           
             
             await buscarProximaEtapaDeRegistros(paginaAtualizada);

    }

    const executarFuncaoBotaoProximo = async ( paginaAtual: number,
                                               qtdPaginasPorPesquisa:number,
                                               arrayTodasPaginasRodape:number[]) => {
        
        let arrayPaginasParaExibir:number[]  = []; 

        let ultimoItemArrayCorrente  = arrayPaginasRodape[arrayPaginasRodape.length - 1];//Pega a ultima página do range corrente         

        let ultimaPaginaTodosArrays = arrayTodasPaginasRodape.length;
        
        calculaHabilitacaoBotaoProximo(paginaAtual);

        if(paginaAtual + 1 === ultimoItemArrayCorrente)            
        {            
            setProximaPaginaAtual();

            await buscarProximaEtapaDeRegistros(paginaAtual + 1);

            arrayPaginasParaExibir = arrayPaginasRodape;                                            
        }
        else
        if(paginaAtual === ultimoItemArrayCorrente)
        {
            if(ultimaPaginaTodosArrays > paginaAtual)
            {
                let proximoUltimoItemArray = ultimoItemArrayCorrente + qtdPaginasPorPesquisa;                    
                                    
               arrayPaginasParaExibir = arrayTodasPaginasRodape.slice(ultimoItemArrayCorrente, proximoUltimoItemArray);                                      
                
               await buscarProximaEtapaDeRegistros(paginaAtual + 1);             
            }
            else
            {                           
               arrayPaginasParaExibir = arrayPaginasRodape; 
            }
        }
        else
        {
            setProximaPaginaAtual();

            arrayPaginasParaExibir = arrayPaginasRodape;  

            await buscarProximaEtapaDeRegistros(paginaAtual + 1);           
        }
         
        setBotaoAnterior(true);
        setarrayPaginasRodape(arrayPaginasParaExibir); 
    }

    const construirArrayPaginasRodapePadrao = async( qtdPaginasPorPesquisa:number, 
                                                     arrayTodasPaginasRodape:number[]) => {

        let arrayPaginasParaExibir:number[]  = []; 

        if(arrayTodasPaginasRodape.length === 1)        
            setBotaoProximo(false);            
        else
            setBotaoProximo(true); 

        setBotaoAnterior(false);

         arrayPaginasParaExibir = arrayTodasPaginasRodape.slice(0, qtdPaginasPorPesquisa);                                          
            
         setarrayPaginasRodape(arrayPaginasParaExibir);     

         setProximaPaginaAtual();            
    }

        return(
            <>
               <nav aria-label="Navegação de página exemplo">
                    <ul className="pagination justify-content-end">

                       {paginaAnterior ? 
                            <li className="page-item">
                                <a className="page-link" href="#" onClick={(e) => atualizarRodapePaginacao(paginaAtual, true, false, e)}>Anterior</a>
                            </li>
                         :
                            <li className="page-item disabled">
                                <a className="page-link" href="#">Anterior</a>
                            </li>
                       }
                        
                        {
                          arrayPaginasRodape.length > 0 && 
                                arrayPaginasRodape.map(pagina => {
                                                                    return <li key={pagina}
                                                                               className={classNames({
                                                                                                       "page-item active": pagina === paginaAtual,
                                                                                                       "page-item": pagina !== paginaAtual
                                                                                                     })}                                                                              
                                                                            >                                                                                   
                                                                            <a className="page-link" 
                                                                               href="#"                                                                               
                                                                               onClick={(e) => handleClickPagina(pagina, e)}                                                                               
                                                                              >{pagina}
                                                                            </a>
                                                                        </li>
                                                                 }
                                                      )
                         
                        }  

                        {
                            paginaProxima ? 
                                <li className="page-item">
                                  <a className="page-link" href="#" onClick={(e) => atualizarRodapePaginacao(paginaAtual, false, true, e)}>Próximo</a>
                               </li>
                            :
                                <li className="page-item disabled">
                                  <a className="page-link" href="#">Próximo</a>
                               </li>
                        }
                        
                    </ul>
                </nav>
            </>
        )
}

export default Paginacao;