import Dashboard from '../../types/Dashboard';
import {Bar} from 'react-chartjs-2';
import { CurrencyUtils } from '../../Utils/CurrencyUtils';

const ChartSemanal = (dash:{dashboard: Dashboard}) => {        

     const {dashboard} = dash;  
     
     let chartData = {
         labels: [
             'Domingo','Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'
         ],
         datasets:[
             {
                label: 'Valores Recebidos',
                data:[
                   CurrencyUtils.DecimalParaString(dashboard.domingoConsolidada),
                   CurrencyUtils.DecimalParaString(dashboard.segundaConsolidada),
                   CurrencyUtils.DecimalParaString(dashboard.tercaConsolidada),
                   CurrencyUtils.DecimalParaString(dashboard.quartaConsolidada),
                   CurrencyUtils.DecimalParaString(dashboard.quintaConsolidada),
                   CurrencyUtils.DecimalParaString(dashboard.sextaConsolidada),
                   CurrencyUtils.DecimalParaString(dashboard.sabadoConsolidada),
                ],
                backgroundColor:[
                    'rgba(99, 255, 112, 0.76)',
                    'rgba(99, 255, 112, 0.76)',
                    'rgba(99, 255, 112, 0.76)',
                    'rgba(99, 255, 112, 0.76)',
                    'rgba(99, 255, 112, 0.76)',
                    'rgba(99, 255, 112, 0.76)',
                    'rgba(99, 255, 112, 0.76)',
                ]
             },
             {
                label: 'Valores a Receber',
                data:[
                   CurrencyUtils.DecimalParaString(dashboard.domingoProjetada),
                   CurrencyUtils.DecimalParaString(dashboard.segundaProjetada),
                   CurrencyUtils.DecimalParaString(dashboard.tercaProjetada),
                   CurrencyUtils.DecimalParaString(dashboard.quartaProjetada),
                   CurrencyUtils.DecimalParaString(dashboard.quintaProjetada),
                   CurrencyUtils.DecimalParaString(dashboard.sextaProjetada),
                   CurrencyUtils.DecimalParaString(dashboard.sabadoProjetada),
                ],
                backgroundColor:[
                    'rgba(211, 192, 196, 0.767)',
                    'rgba(211, 192, 196, 0.767)',
                    'rgba(211, 192, 196, 0.767)',
                    'rgba(211, 192, 196, 0.767)',
                    'rgba(211, 192, 196, 0.767)',
                    'rgba(211, 192, 196, 0.767)',
                    'rgba(211, 192, 196, 0.767)',
                ]
             },

         ]
     };


    return(
        <>        
           <Bar
              data={chartData}
           />                
        </>
    )
}

export default ChartSemanal;