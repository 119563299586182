import ResponseResult from "../types/ResponseResult";
import { BaseService } from "./BaseService";
import Cliente from '../types/Cliente';
import async from "react-select/async";

 export default class ClienteService extends BaseService {     
    
       constructor()
       {
        super();

        this.urlCliente                                    = '/Cliente/';
        this.metodoObterPaginado                           = this.urlCliente + 'ObterPaginado';
        this.metodoCadastrar                               = this.urlCliente + 'Cadastrar';
        this.metodoAlterar                                 = this.urlCliente + 'Alterar';
        this.metodoExcluir                                 = this.urlCliente + 'Excluir';
        this.metodoCadastrarLote                           = this.urlCliente + 'InserirLote';
        this.metodoBuscarParaSelectItem                    = this.urlCliente + 'ObterSelectItem';
        this.metodoObterSelectItemSemContato               = this.urlCliente + 'ObterSelectItemSemContato';
        this.metodoObterRelatorioClientesSemContatoPdf     = this.urlCliente + 'ObterPdfClientesSemContato';
        this.metodoObterRelatorioClientesSemContatoExcel   = this.urlCliente + 'ObterExcelClientesSemContato';
        this.metodoObterRelatorioProspectosSemContatoExcel = this.urlCliente + 'ObterExcelProspectadosSemContato';
        this.metodoObterClientesExcel                      = this.urlCliente + 'GerarExcel';
       }
       
       private urlCliente: string;
       private metodoObterPaginado: string;
       private metodoCadastrar: string;
       private metodoCadastrarLote: string;
       private metodoAlterar: string;
       private metodoExcluir: string;
       private metodoBuscarParaSelectItem: string;
       private metodoObterSelectItemSemContato: string;
       private metodoObterRelatorioClientesSemContatoPdf:string;
       private metodoObterRelatorioClientesSemContatoExcel:string;
       private metodoObterRelatorioProspectosSemContatoExcel:string;
       private metodoObterClientesExcel:string;

       buscarClientesPaginado = async (idCliente:number = 0,
                                       qtdpaginasSkip: number = 0, 
                                       qtdPorPagina: number = 0, 
                                       nomeCliente: string = '',
                                       tipo_Cliente:string = '1',
                                       buscar_Clientes_Sem_Contato:boolean = false,
                                       buscarProspectadosSemanal:boolean = false,
                                       buscarProspectadosMensal:boolean = false,
                                       buscarProspectadosAnual:boolean = false,
                                       dataPesquisa:string,
                                       ano: number,
                                       localizacao?: string) => {
             
             let resultado: ResponseResult = {};    
             
             let params = {
                 Id: idCliente,                    
                 Nome: nomeCliente,
                 SkipPaginas: qtdpaginasSkip,
                 QtdPorPagina: qtdPorPagina,
                 Tipo_Cliente: tipo_Cliente,
                 Data_Contato: dataPesquisa,
                 buscarClientesSemContato: buscar_Clientes_Sem_Contato,
                 ProspectadoSemanal: buscarProspectadosSemanal,
                 ProspectadoMensal: buscarProspectadosMensal,
                 ProspectadoAnual: buscarProspectadosAnual,
                 Ano: ano,
                 Localizacao: localizacao
             }

             resultado = await this.GetData(this.metodoObterPaginado, params, true, null);

             return resultado;
       }

       cadastrarCliente = async(Cliente: Cliente) => {
             let resultado: ResponseResult = {};

             resultado = await this.PostData(this.metodoCadastrar, Cliente, true, null);

             return resultado;
       }

       cadastrarClienteEmLote = async(Clientes: Cliente[]) => {
            let resultado: ResponseResult = {};

            resultado = await this.PostData(this.metodoCadastrar, Clientes, true, null);

            return resultado;
      }

       alterarCliente = async(Cliente: Cliente) => {
            let resultado: ResponseResult = {};

            resultado = await this.PatchData(this.metodoAlterar, Cliente, true, null);

            return resultado;
       }

       excluirCliente = async(id: number) => {
            let resultado: ResponseResult = {};

            resultado = await this.DeleteData(this.metodoExcluir, id, true, null);

            return resultado;
       }

       ObterSelectItem = async(nome_empresa:string) => {
             let resultado: ResponseResult = {};

            let params = {
                  nome_empresa: nome_empresa
            }

             resultado = await this.GetData(this.metodoBuscarParaSelectItem, params, true, null);

             return resultado;
       }

       ObterSelectItemSemContato = async(nome_empresa:string, tipo_cliente:string) => {
            let resultado: ResponseResult = {};

           let params = {
                 nome_empresa: nome_empresa,
                 tipo_cliente: tipo_cliente
           }

            resultado = await this.GetData(this.metodoObterSelectItemSemContato, params, true, null);

            return resultado;
      }

      ObterClientesSemContatoPdf = async() => {
            let resultado: ResponseResult = {};

            resultado = await this.GetData(this.metodoObterRelatorioClientesSemContatoPdf, null, true,null);

            return resultado;
      }

      ObterClientesSemContatoExcel = async() => {
            let resultado: ResponseResult = {};

            resultado = await this.GetData(this.metodoObterRelatorioClientesSemContatoExcel, null, true,null);

            return resultado;
      }

      ObterProspectosSemContatoExcel = async() => {
            let resultado: ResponseResult = {};

            resultado = await this.GetData(this.metodoObterRelatorioProspectosSemContatoExcel, null, true,null);

            return resultado;
      }

      ObterClientesExcel = async(nomeCliente:string, dataContato:string, localizacao?:string) => {
            let resultado: ResponseResult = {};

            let params = {
                 nome: nomeCliente,
                 Data_Contato: dataContato,
                 Localizacao: localizacao
            }

            resultado = await this.GetData(this.metodoObterClientesExcel, params, true,null);

            return resultado;
      }
};

