import React, { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import UsuarioService from '../../services/UsuarioService';
import Usuario from '../../types/usuario';
import StringUtils from '../../Utils/StringUtils';

const PainelDadosUsuario: React.FC = () => {

    let usuarioService: UsuarioService = new UsuarioService();

    const[carregandoDados, setCarregandoDados] = useState<boolean>(false)
    const[salvandoDados, setSalvandoDados] = useState<boolean>(false)

    useEffect(() => {              
        obterDadosUsuario()           
    }, [])

    const[formUsuario, setFormUsuario] = useState<Usuario>({
        Id:0,
        Ativo:"Sim",
        Data_Cadastro: "",
        Data_Inativacao:"",
        Email:"",
        GrupoUsuariosLocucaoId:0,
        Motivo_Inativacao:"",
        Nome:"",
        Senha:"",
        ConfirmarSenha:"",
        diasParaMostrarClientesSemContato: 0
    })

    const[tipoAcesso, setTipoAcesso] = useState<string>('')

    const handleInputchange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name , value} = event.target;
        setFormUsuario({...formUsuario, [name]: value});  
    }; 

    const obterDadosUsuario = async() => {
        
        setCarregandoDados(true)

        let usuario: any =  await usuarioService.obterDadosUsuario();

        setCarregandoDados(false)

        setFormUsuario({
            Id: usuario.id,
            Ativo:"Sim",
            Data_Cadastro: usuario.data_Cadastro,
            Data_Inativacao: usuario.data_Inativacao,
            Email: usuario.email,
            GrupoUsuariosLocucaoId:0,
            Motivo_Inativacao: usuario.motivo_Inativacao,
            Nome:usuario.nome,
            Senha:"",
            ConfirmarSenha:"",
            dataLimiteContrato: usuario.dataLimiteContrato,
            diasParaMostrarClientesSemContato: usuario.diasParaMostrarClientesSemContato
        })

        if(usuario.tipoContrato === 1)
           setTipoAcesso('Mensal')
        else
        if(usuario.tipoContrato === 2)
           setTipoAcesso('Semestral')
        else
        if(usuario.tipoContrato === 3) 
           setTipoAcesso('Anual')
        else
        setTipoAcesso('Teste de 15 dias')           
    }


    const salvarAlteracoes = async() => {
        
        if(!StringUtils.isNullOrEmpty(formUsuario.Senha) ||
           ! StringUtils.isNullOrEmpty(formUsuario.ConfirmarSenha)
          )
          {
            if(formUsuario.Senha !== formUsuario.ConfirmarSenha)        
            {
              toast("As senhas estão diferentes",
                      {
                          type:"warning"
                      }
                   )
    
              return;
            }
            
            if(formUsuario.Senha.length < 8)
            {
                toast("A senha precisa ter no mínimo 8 caracteres",
                      {
                          type:"warning"
                      }
                   )
    
              return;
            }
          }

          if(formUsuario.diasParaMostrarClientesSemContato! <= 0)
          {
            toast("O parâmetro de dias sem contato precisa ser superior a zero",
                      {
                          type:"warning"
                      }
                   )
    
              return;
          }

        setSalvandoDados(true)

        let salvouAlteracoes = await usuarioService.salvarAlteracoesUsuario(formUsuario)

        setSalvandoDados(false)

        if(salvouAlteracoes)
           toast("Alteração realizada com sucesso, efetue um novo login para obter os dados atualizados",
              {
                  type:"success"
              }
           )
    }

    return(
        <>
           <form id="frmDadosUsuario">
               <div className="col-lg-12">
                   <div className="card shadow-sm">
                        <div className="card-header">Dados de cadastro</div>
                            {carregandoDados ? <div className="text-center mt-2"><Spinner/> Carregando...</div>
                                             : 
                             <div className="card-body">
                                <div className="alert alert-danger" role="alert">Após realizar a alteração dos dados, é recomendado efetuar um novo login para obtê-los atualizados.</div>
                                <div className="form-row">
                                     <div className="form-group col-lg-6">
                                          <label>Login:</label>
                                             <input type="text"
                                                    className="form-control text-left" 
                                                    id="Nome" 
                                                    name="Nome" 
                                                    readOnly={true}
                                                    value={formUsuario.Nome}
                                                    style={{"cursor": "not-allowed"}}
                                             />
                                     </div>
                                     <div className="form-group col-lg-6">
                                          <label>Email:</label>
                                             <input type="text"
                                                    className="form-control text-left" 
                                                    id="Email" 
                                                    name="Email" 
                                                    readOnly={true}
                                                    value={formUsuario.Email}
                                                    style={{"cursor": "not-allowed"}}
                                             />
                                     </div>
                                </div>
                                <div className="form-row">
                                   <div className="form-group col-lg-6">
                                       <label>Data para expiração do acesso:</label>
                                            <input type="text"
                                               className="form-control text-left" 
                                               id="DataLimiteContrato" 
                                               name="DataLimiteContrato" 
                                               readOnly={true}
                                               value={formUsuario.dataLimiteContrato}
                                               style={{"cursor": "not-allowed"}}
                                            />
                                   </div>
                                   <div className="form-group col-lg-6">
                                       <label>Tipo de acesso:</label>
                                            <input type="text"
                                               className="form-control text-left" 
                                               id="tipoAcesso" 
                                               name="tipoAcesso" 
                                               readOnly={true}
                                               value={tipoAcesso}
                                               style={{"cursor": "not-allowed"}}
                                            />
                                   </div>
                                </div>

                                <div className="form-row">
                                <div className="form-group col-lg-9">
                                        <label>Informe a partir de quantos dias você deseja receber notificações de clientes e prospectados sem contato:</label>
                                           <input type="number"
                                            className="form-control text-left" 
                                            id="diasParaMostrarClientesSemContato" 
                                            name="diasParaMostrarClientesSemContato"                                             
                                            placeholder=""
                                            min={0}
                                            max={365}
                                            value={formUsuario.diasParaMostrarClientesSemContato}
                                            onChange={handleInputchange}
                                         />
                                   </div>
                                </div>
                                  
                                <div className="form-row">                                   
                                   <div className="form-group col-lg-4">
                                        <label>Nova senha:</label>
                                           <input type="password"
                                            className="form-control text-left" 
                                            id="Senha" 
                                            name="Senha"                                             
                                            placeholder="********"
                                            value={formUsuario.Senha}
                                            onChange={handleInputchange}
                                         />
                                   </div>
                                   <div className="form-group col-lg-4">
                                        <label>Confirmar nova senha:</label>
                                           <input type="password"
                                            className="form-control text-left" 
                                            id="ConfirmarSenha" 
                                            name="ConfirmarSenha"                                             
                                            placeholder="********"
                                            value={formUsuario.ConfirmarSenha}
                                            onChange={handleInputchange}
                                         />
                                   </div>
                                </div>

                                <div className="form-group col-lg-12 text-right">
                                      <label></label>
                                      {salvandoDados ? <button className="btn btn-sm btn-outline-warning" 
                                                               type="button"                                                             
                                                               style={{"cursor": "not-allowed"}}>Salvando...<Spinner />
                                                       </button>
                                                     : <button className="btn btn-sm btn-outline-warning" 
                                                               type="button" onClick={salvarAlteracoes}>
                                                            Salvar alterações
                                                       </button>  
                                      }                                            
                                                                                     
                               </div>

                         </div>
                       }
                   </div>
               </div>
           </form>
        </>
    )
}

export default PainelDadosUsuario;