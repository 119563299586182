import { ChangeEvent, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { toast } from "react-toastify";
import ConfigMetasService from "../../../services/ConfigMetasService";
import ConfigMetas, { TipoPeriodoMeta, TipoMeta } from '../../../types/ConfigMetas';

const MetasFC: React.FC = () => {

    const [ativoCastingsAprovados, setAtivoCastingsAprovados] = useState('Sim');  
    const [ativoValorJobs, setAtivoValorJobs] = useState('Sim'); 
    const [ativoConversaoProspectados, setAtivoConversaoProspectados] = useState('Sim'); 

    let configMetasService: ConfigMetasService = new ConfigMetasService();
    
    const[formDataCastingsAprovados, setFormDataCastingsAprovados] = useState<ConfigMetas>({
        id:0,
        ativo:true,
        tipoMeta: TipoMeta.CastingsAprovados,
        tipoPeriodoMeta: TipoPeriodoMeta.Anual,
        valor: '0'
    })

    const[formDataValorJobs, setformDataValorJobs] = useState<ConfigMetas>({
        id:0,
        ativo:true,
        tipoMeta: TipoMeta.ValorJobs,
        tipoPeriodoMeta: TipoPeriodoMeta.Anual,
        valor: '0'
    })

    const[formDataConversaoProspectados, setformDataConversaoProspectados] = useState<ConfigMetas>({
        id:0,
        ativo:true,
        tipoMeta: TipoMeta.ConversaoProspectados,
        tipoPeriodoMeta: TipoPeriodoMeta.Anual,
        valor: '0'
    })

    useEffect(() => {
        obterMetasConfiguradas();
    }, [])
    
    const obterMetasConfiguradas = async() => {

        let resultado = await configMetasService.obterListaConfigMeta();

        if(resultado.status === 200)
        {
            resultado.dataList?.forEach((f:ConfigMetas | any ) => {
                if(f.tipoMeta === TipoMeta.ConversaoProspectados)  
                {                                
                   setformDataConversaoProspectados(f)   
                   setAtivoConversaoProspectados(f.ativo ? 'Sim' : 'Não')
                }             
                else
                if(f.tipoMeta === TipoMeta.ValorJobs)
                {
                   setformDataValorJobs(f)
                   setAtivoValorJobs(f.ativo ? 'Sim' : 'Não')
                }
                else
                if(f.tipoMeta === TipoMeta.CastingsAprovados)
                {
                   setFormDataCastingsAprovados(f)    
                   setAtivoCastingsAprovados(f.ativo ? 'Sim' : 'Não')
                }        
            })
        }
    }

    const handleSelectTipoPeriodoCastingsAprovados = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name , value} = event.target;        
        setFormDataCastingsAprovados({...formDataCastingsAprovados, [name]:parseInt(value)});         
    }

    const handleSelectTipoPeriodoValorJobs = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name , value} = event.target;        
        setformDataValorJobs({...formDataValorJobs, [name]:parseInt(value)});         
    }

    const handleSelectTipoPeriodoConversaoProspectados = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name , value} = event.target;        
        setformDataConversaoProspectados({...formDataConversaoProspectados, [name]:parseInt(value)});         
    }

    const handleSelectAtivoCastingsAprovados = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name , value} = event.target;    
        setAtivoCastingsAprovados(value)

        if(value === "Sim")
          setFormDataCastingsAprovados({...formDataCastingsAprovados, [name]: true})
        else
          setFormDataCastingsAprovados({...formDataCastingsAprovados, [name]: false})
    }

    const handleSelectAtivoValorJobs = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name , value} = event.target;    
        setAtivoValorJobs(value)

        if(value === "Sim")
          setformDataValorJobs({...formDataValorJobs, [name]: true})
        else
          setformDataValorJobs({...formDataValorJobs, [name]: false})
    }

    const handleSelectAtivoConversaoProspectados = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name , value} = event.target;    
        setAtivoConversaoProspectados(value)

        if(value === "Sim")
          setformDataConversaoProspectados({...formDataConversaoProspectados, [name]: true})
        else
          setformDataConversaoProspectados({...formDataConversaoProspectados, [name]: false})
    }

    const setarValorMetaProspectado = async(event:any) => {
        const {name , value} = event.target;  
          setformDataConversaoProspectados({...formDataConversaoProspectados, [name]:value})
    }

    const setarValorMetaCastingsAprovados = async(event:any) => {
        const {name , value} = event.target;            
          setFormDataCastingsAprovados({...formDataCastingsAprovados, [name]:value})
    }

    const setarValorMetaJobs = async(value:string | any) => {         
         setformDataValorJobs({...formDataValorJobs, ['valor']: value !== undefined ? value : "0"})
    }

    const salvarConfigMeta = async(formMetaConfig: ConfigMetas) => {
        
        if(formMetaConfig.valor === '0')
        {
            toast('O campo valor é obrigatório', {
                type: 'warning'                
            });

            return;
        }

         let resultado = formMetaConfig.id === 0
                         ? await configMetasService.cadastrarMeta(formMetaConfig)
                         : await configMetasService.alterarMeta(formMetaConfig)

         if(resultado.status === 200)
         {
            toast('Meta configurada com sucesso', {
                type: 'success'                
            }); 

            await obterMetasConfiguradas();
         }                         
    }

    return(
        <>
          <form method="post" 
                className="needs-validation form" 
                //onSubmit={SalvarCliente}
                autoComplete="off"
            >
                <div className="row">
                        <div className="col-lg-12">
                            <div className="card shadow-sm">
                                <div className="card-header">Configurações de metas</div>
                                <div className="card-body">
                                    
                                    <div className="form-row">
                                        <div className="form-group col-lg-3">                                                                                
                                             <label className="form-control-plaintext">Conversão de prospectados</label>
                                        </div>
                                        <div className="form-group col-lg-2">
                                             <select id="tipoPeriodoMeta" 
                                                   name="tipoPeriodoMeta"
                                                   onChange={handleSelectTipoPeriodoConversaoProspectados}
                                                   className="form-control"
                                                   value={formDataConversaoProspectados.tipoPeriodoMeta}
                                            >                                                                                                
                                            <option value={TipoPeriodoMeta.Anual} selected={true}>Anual</option>
                                            <option value={TipoPeriodoMeta.Mensal}>Mensal</option>
                                            <option value={TipoPeriodoMeta.Semanal}>Semanal</option>
                                                                                               
                                            </select>
                                        </div>

                                        <div className="form-group col-lg-1">
                                             <label className="form-control-plaintext">Ativo</label>
                                        </div> 
                                        <div className="form-group col-lg-2">
                                             <select id="ativo"
                                                     name="ativo"
                                                     onChange={handleSelectAtivoConversaoProspectados}
                                                     className="form-control"
                                                     value={ativoConversaoProspectados}
                                             >
                                                 <option value={'Sim'} selected>Sim</option>
                                                 <option value={'Não'}>Não</option>
                                             </select>
                                        </div>

                                        <div className="form-group col-lg-2">
                                             <label className="form-control-plaintext">Quantidade</label>
                                        </div>
                                        <div className="form-group col-lg-2">
                                             <input type="number" 
                                                    pattern="[0-9]*"
                                                    onChange={setarValorMetaProspectado}
                                                    id="valor"
                                                    name="valor"
                                                    value={formDataConversaoProspectados.valor} 
                                                    className="form-control"
                                                    min={0}
                                             />
                                        </div>

                                        <div className="form-group col-lg-4">
                                            <label></label>                                            
                                                <button className="btn btn-sm btn-outline-warning" type="button" onClick={() => salvarConfigMeta(formDataConversaoProspectados)}>
                                                    Salvar
                                                </button>
                                                {formDataConversaoProspectados.id === 0 ? <b>  (Não configurado)</b> : ''}                                           
                                        </div>
                                        
                                    </div>
                                     
                                      <hr className="col-xs-12"/>
                                      
                                      <div className="form-row">
                                        <div className="form-group col-lg-3">                                                                                
                                             <label className="form-control-plaintext">Castings aprovados</label>
                                        </div>
                                        <div className="form-group col-lg-2">
                                             <select id="tipoPeriodoMeta" 
                                                   name="tipoPeriodoMeta"
                                                   onChange={handleSelectTipoPeriodoCastingsAprovados}
                                                   className="form-control"
                                                   value={formDataCastingsAprovados.tipoPeriodoMeta}
                                            >                                                                                                
                                            <option value={TipoPeriodoMeta.Anual} selected={true}>Anual</option>
                                            <option value={TipoPeriodoMeta.Mensal}>Mensal</option>
                                            <option value={TipoPeriodoMeta.Semanal}>Semanal</option>
                                                                                               
                                            </select>
                                        </div>

                                        <div className="form-group col-lg-1">
                                             <label className="form-control-plaintext">Ativo</label>
                                        </div> 
                                        <div className="form-group col-lg-2">
                                             <select id="ativo"
                                                     name="ativo"
                                                     onChange={handleSelectAtivoCastingsAprovados}
                                                     className="form-control"
                                                     value={ativoCastingsAprovados}
                                             >
                                                 <option value={'Sim'} selected>Sim</option>
                                                 <option value={'Não'}>Não</option>
                                             </select>
                                        </div>

                                        <div className="form-group col-lg-2">
                                             <label className="form-control-plaintext">Quantidade</label>
                                        </div>
                                        <div className="form-group col-lg-2">
                                             <input type="number" 
                                                    pattern="[0-9]*"
                                                    onChange={setarValorMetaCastingsAprovados}
                                                    id="valor"
                                                    name="valor"
                                                    value={formDataCastingsAprovados.valor} 
                                                    className="form-control"
                                                    min={0}
                                             />
                                        </div>

                                        <div className="form-group col-lg-4">
                                            <label></label>                                            
                                                <button className="btn btn-sm btn-outline-warning" type="button" onClick={() => salvarConfigMeta(formDataCastingsAprovados)}>
                                                    Salvar
                                                </button>
                                                {formDataCastingsAprovados.id === 0 ? <b>  (Não configurado)</b> : ''}                                           
                                        </div>
                                        
                                    </div>

                                    <hr className="col-xs-12"/>

                                    <div className="form-row">
                                        <div className="form-group col-lg-3">                                                                                
                                             <label className="form-control-plaintext">Valores de Job's</label>
                                        </div>
                                        <div className="form-group col-lg-2">
                                             <select id="tipoPeriodoMeta" 
                                                   name="tipoPeriodoMeta"
                                                   onChange={handleSelectTipoPeriodoValorJobs}
                                                   className="form-control"
                                                   value={formDataValorJobs.tipoPeriodoMeta}
                                            >                                                                                                
                                            <option value={TipoPeriodoMeta.Anual} selected={true}>Anual</option>
                                            <option value={TipoPeriodoMeta.Mensal}>Mensal</option>
                                            <option value={TipoPeriodoMeta.Semanal}>Semanal</option>
                                                                                               
                                            </select>
                                        </div>

                                        <div className="form-group col-lg-1">
                                             <label className="form-control-plaintext">Ativo</label>
                                        </div> 
                                        <div className="form-group col-lg-2">
                                             <select id="ativo"
                                                     name="ativo"
                                                     onChange={handleSelectAtivoValorJobs}
                                                     className="form-control"
                                                     value={ativoValorJobs}
                                             >
                                                 <option value={'Sim'} selected>Sim</option>
                                                 <option value={'Não'}>Não</option>
                                             </select>
                                        </div>

                                        <div className="form-group col-lg-2">
                                             <label className="form-control-plaintext">Valor</label>
                                        </div>
                                        <div className="form-group col-lg-2">
                                             {/* <input type="number" 
                                                    pattern="[0-9]*"
                                                    onChange={setarValorMetaJobs}
                                                    id="valor"
                                                    name="valor"
                                                    value={formDataValorJobs.valor} 
                                                    className="form-control"
                                                    min={0}
                                             /> */}

                                             <CurrencyInput
                                                id="valor"
                                                name="valor"
                                                placeholder="0,00"
                                                defaultValue={0}
                                                decimalsLimit={2}
                                                onValueChange={(value, name) => setarValorMetaJobs(value)}
                                                prefix={"R$"}
                                                className="form-control text-left"
                                                // decimalScale={2}
                                                value={formDataValorJobs.valor}    
                                                min={0}                                            
                                            />
                                        </div>

                                        <div className="form-group col-lg-4">
                                            <label></label>                                            
                                                <button className="btn btn-sm btn-outline-warning" type="button" onClick={() => salvarConfigMeta(formDataValorJobs)}>
                                                    Salvar
                                                </button>
                                                {formDataValorJobs.id === 0 ? <b>  (Não configurado)</b> : ''}                                           
                                        </div>
                                        
                                    </div>

                                </div>
                            </div>
                       </div>
                </div>
            </form>
        </>
    )
}

export default MetasFC;