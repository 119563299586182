import LoginJwt from '../types/LoginJwt';
import PagamentoMercadoPago from '../types/PagamentoMercadoPago';
import ResponseResult from '../types/ResponseResult';
import { BaseService } from './BaseService';

export default class MercadoPagoService extends BaseService
{
    private MERCADOPAGO_PUBLIC_KEY = process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY;

     constructor() {
         super();

          this.urlPagamento = '/Usuario/';
          this.metodoEfetuarPagamentoCartaoCreditoNaoLogado = this.urlPagamento + 'EfetuarAssinaturaNaoLogado';
          this.metodoCadastrarPagamentoPix = this.urlPagamento + 'EfetuarCadastroPagamentoPix';
     }
     
     private urlPagamento:string;
     private metodoEfetuarPagamentoCartaoCreditoNaoLogado:string;
     private metodoCadastrarPagamentoPix;

     obterPublicKey = () =>
     {
        return this.MERCADOPAGO_PUBLIC_KEY!;
     }

     setarLocalStorageUsuarioPix = (accessToken: string) : void => {              
      this.localStorage.salvarTokenUsuario(accessToken);
     };

     setarLocalStorageUsuario = (response: ResponseResult) : void => {
      const loginJwt: LoginJwt | any = response;
              
      this.localStorage.salvarTokenUsuario(loginJwt.data.accessToken);
     };

     efetuarPagamentoCartaoCreditoNaoLogado = async(pagamentoMercadoPago: PagamentoMercadoPago) =>
     {
        let resultado: ResponseResult = {};

        resultado = await this.PostData(this.metodoEfetuarPagamentoCartaoCreditoNaoLogado, pagamentoMercadoPago, false, null);

        if(resultado.status === 200)
          this.setarLocalStorageUsuario(resultado);

        return resultado;
     }

     cadastrarPagamentoPix = async(pagamentoMercadoPago: PagamentoMercadoPago) =>
     {
        let resultado: ResponseResult = {};

        resultado = await this.PostData("Usuario/EfetuarCadastroPagamentoPix", pagamentoMercadoPago, false, null);

        return resultado;
     }
}