import React, { useState } from 'react';
import JobsService from '../../../../services/JobsService';
import ResponseResult from '../../../../types/ResponseResult';
import BaseReport from '../../BaseReport';
import DatePicker from 'react-date-picker';

const JobsPendentesPagamento: React.FC = () => {
      
    const[dtInicioFiltro, setDtInicioFiltro] = useState<Date>(new Date())
    const[dtFimFiltro, setDtFimFiltro]       = useState<Date>(new Date())

    let jobService:JobsService = new JobsService();

    const ObterRelatorioPdf = async (): Promise<ResponseResult> => 
    {        
       let resultado = 
          await jobService.GerarRelatorioJobsPendentesPagamentoPdf(dtInicioFiltro.toDateString(),
                                                                   dtFimFiltro.toDateString());        

       return resultado
    }

    const ObterRelatorioExcel = async (): Promise<ResponseResult> => 
    {        
       let resultado = 
          await jobService.GerarRelatorioJobsPendentesPagamentoExcel(dtInicioFiltro.toDateString(),
                                                                     dtFimFiltro.toDateString());       

       return resultado;
    }

    const onDatePickerDtIniChange = (event: any) => {      
        
        if(event == null)
           event = new Date()
        
        setDtInicioFiltro(event)        
    }

    const onDatePickerDtFimChange = (event: any) => {   
        
        if(event == null)
        event = new Date()
        
        setDtFimFiltro(event)        
    }

    return(
        <>
            <form method="post" 
                className="needs-validation formCadastro"                 
                autoComplete="off"                
            >
                <div className="row">
                  <div className="col-lg-12">
                      <div className="card shadow-sm">
                         <div className="card-header">Informe as datas para a pesquisa</div>
                            <div className="card-body">
                               <div className="form-row">
                                    <div className="form-group col-lg-3">
                                         <label>Início dos jobs realizados</label>
                                              <DatePicker
                                                 onChange={onDatePickerDtIniChange}
                                                 value={dtInicioFiltro}
                                                 className="form-control text-left"
                                                 maxDate={new Date()}                                                                                                        
                                              />
                                    </div> 

                                    <div className="form-group col-lg-3">
                                         <label>Fim dos jobs realizados</label>
                                              <DatePicker
                                                 onChange={onDatePickerDtFimChange}
                                                 value={dtFimFiltro}
                                                 className="form-control text-left"
                                                 maxDate={new Date()}                                                                                                        
                                              />
                                    </div> 
                              </div>
                            </div>
                      </div>
                  </div>
                </div>
            </form>
            <hr/>

            <BaseReport nome="Relatório de jobs pendentes de pagamento" 
                        obterRelatorioPdf={ObterRelatorioPdf}
                        obterRelatorioExcel={ObterRelatorioExcel}
            />
        </>
    )
}

export default JobsPendentesPagamento;