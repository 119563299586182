import React from 'react';
import ClienteService from '../../../services/ClienteService';
import ResponseResult from '../../../types/ResponseResult';
import BaseReport from '../BaseReport';

const ClientesSemContato: React.FC = () => {
        
    let clienteService:ClienteService = new ClienteService();

    const ObterRelatorioPdf = async (): Promise<ResponseResult> => 
    {        
       let resultado = await clienteService.ObterClientesSemContatoPdf();        

       return resultado
    }

    const ObterRelatorioExcel = async (): Promise<ResponseResult> => 
    {        
       let resultado = await clienteService.ObterClientesSemContatoExcel();        

       return resultado;
    }

    return(
        <>
            <BaseReport nome="Relatório de clientes sem contato" 
                        obterRelatorioPdf={ObterRelatorioPdf}
                        obterRelatorioExcel={ObterRelatorioExcel}
            />
        </>
    )
}

export default ClientesSemContato;