import StringUtils from "./StringUtils";

export class LocalStorageUtils{

    public static usuarioLogado(): boolean {

        var usuario = localStorage.getItem('locucao.token');

        if(!StringUtils.isNullOrEmpty(usuario))
           return true;
        else
           return false;
    }

    public salvarDadosLocaisUsuario(response: any){
       this.salvarTokenUsuario(response.accessToken);
       this.salvarUsuario(response.userToken);
    }

    public static limparDadosLocaisUsuario(){
        localStorage.removeItem('locucao.token');
        localStorage.removeItem('locucao.user');
    }

    public obterTokenUsuario() : string | any{
      return localStorage.getItem('locucao.token');
    }

    public salvarTokenUsuario(token: string){
       localStorage.setItem('locucao.token', token);
    }

    public salvarUsuario(user: string){
       localStorage.setItem('locucao.user', JSON.stringify(user));
    }

}