import { BaseService } from "./BaseService";
import ResponseResult from "../types/ResponseResult";
import Jobs from "../types/jobs";
import { ModoExibicaoDashboard } from "../types/Dashboard";

export default class JobsService extends BaseService
{
    constructor()
    {
        super();

        this.urlJobs                          = '/Job/';
        this.metodoObterPaginado              = this.urlJobs + 'ObterPaginado';
        this.metodoCadastrar                  = this.urlJobs + 'Cadastrar';
        this.metodoAlterar                    = this.urlJobs + 'Alterar';
        this.metodoExcluir                    = this.urlJobs + 'Excluir';  
        this.metodoObterTiposJob              = this.urlJobs + 'ObterTiposJob';    
        this.metodoObterCastingPaginado       = this.urlJobs + 'Castings-ObterPaginado';  
        this.metodoObterTotalJobsPaginada     = this.urlJobs + 'ObterTotalJobsPaginada';  
        this.metodoObterJobsAPagarPaginada    = this.urlJobs + 'ObterJobsAPagarPaginada';  
        this.metodoObterJobsPagosPaginada     = this.urlJobs + 'ObterJobsPagosPaginada';  
        this.metodogerarjobsExcel             = this.urlJobs + 'GerarJobsExcel';
        this.metodoGerarRelatorioJobsPagosPdf = this.urlJobs + 'RelatorioJobsPagosPdf';
        this.metodoGerarRelatorioJobsPagosExcel = this.urlJobs + 'RelatorioJobsPagosExcel';
        this.metodoGerarRelatorioJobsPendentesPagamentoPdf = this.urlJobs + 'RelatorioJobsPendentesPagamentoPdf';
        this.metodoGerarRelatorioJobsPendentesPagamentoExcel= this.urlJobs + 'RelatorioJobsPendentesPagamentoExcel';
    }
       
       private urlJobs: string;
       private metodoObterPaginado: string;
       private metodoCadastrar: string;       
       private metodoAlterar: string;
       private metodoExcluir: string;
       private metodoObterTiposJob: string;
       private metodoObterCastingPaginado: string;
       private metodoObterTotalJobsPaginada: string;
       private metodoObterJobsAPagarPaginada: string;
       private metodoObterJobsPagosPaginada: string;
       private metodogerarjobsExcel:string;
       private metodoGerarRelatorioJobsPagosPdf:string;
       private metodoGerarRelatorioJobsPagosExcel:string;
       private metodoGerarRelatorioJobsPendentesPagamentoPdf:string;
       private metodoGerarRelatorioJobsPendentesPagamentoExcel:string;

            buscarJobsPaginado = async (qtdpaginasSkip: number = 0, 
                qtdPorPagina: number = 10, 
                clienteId: string = '0',
                tipoJob:number = 0,
                destinoJob:number = 0,
                mesReferencia:string = '0',
                pago: boolean = false,
                localizacao?:string) => {

                let resultado: ResponseResult = {};    

                let params = {
                    ClienteId: clienteId === '' ? '0'  : clienteId,
                    SkipPaginas: qtdpaginasSkip,
                    QtdPorPagina: qtdPorPagina,
                    TipoJob: tipoJob,
                    DestinoJob: destinoJob,
                    Pago: pago,
                    MesReferencia: mesReferencia,
                    Localizacao: localizacao
                }

              resultado = await this.GetData(this.metodoObterPaginado, params, true, null);

              return resultado;
        }

        GerarRelatorioJobsPagosPdf = async (dataInicioPgto:string, dataFimPgto:string) =>{
            let resultado: ResponseResult = {}; 

            let params = {
                dataIniPagamentoJob: dataInicioPgto,
                dataFimPagamentoJob: dataFimPgto
            }

            resultado = await this.GetData(this.metodoGerarRelatorioJobsPagosPdf, params, true, null);

            return resultado;
        }

        GerarRelatorioJobsPagosExcel = async (dataInicioPgto:string, dataFimPgto:string) =>{
            let resultado: ResponseResult = {}; 

            let params = {
                dataIniPagamentoJob: dataInicioPgto,
                dataFimPagamentoJob: dataFimPgto
            }

            resultado = await this.GetData(this.metodoGerarRelatorioJobsPagosExcel, params, true, null);

            return resultado;
        }

        GerarRelatorioJobsPendentesPagamentoPdf = async (dataInicioRealizado:string, dataFimRealizado:string) =>{
            let resultado: ResponseResult = {}; 

            let params = {
                dataIniRealizacao: dataInicioRealizado,
                dataFimRealizacao: dataFimRealizado
            }

            resultado = await this.GetData(this.metodoGerarRelatorioJobsPendentesPagamentoPdf, params, true, null);

            return resultado;
        }

        GerarRelatorioJobsPendentesPagamentoExcel = async (dataInicioRealizado:string, dataFimRealizado:string) =>{
            let resultado: ResponseResult = {}; 

            let params = {
                dataIniRealizacao: dataInicioRealizado,
                dataFimRealizacao: dataFimRealizado
            }

            resultado = await this.GetData(this.metodoGerarRelatorioJobsPendentesPagamentoExcel, params, true, null);

            return resultado;
        }

        ObterCastingsPendentesPaginada = async (modoExibicaoDashboard: ModoExibicaoDashboard,
            clienteIdPesquisa: number = 0,
            qtdpaginasSkip: number = 0, 
            qtdPorPagina: number = 10, 
            ano: number
            ) => {

            let resultado: ResponseResult = {};    

            let params = {
                dashboardTipoBuscaCasting: modoExibicaoDashboard,
                ClienteIdPesquisa: clienteIdPesquisa,
                SkipPaginas: qtdpaginasSkip,
                QtdPorPagina: qtdPorPagina,
                Ano: ano
            }

          resultado = await this.GetData(this.metodoObterCastingPaginado, params, true, null);

          return resultado;
        }

        ObterTotalJobsPaginada = async (modoExibicaoDashboard: ModoExibicaoDashboard,
            clienteIdPesquisa: number = 0,
            qtdpaginasSkip: number = 0, 
            qtdPorPagina: number = 10, 
            ano: number
            ) => {

            let resultado: ResponseResult = {};    

            let params = {
                dashboardTipoBuscaCasting: modoExibicaoDashboard,
                ClienteIdPesquisa: clienteIdPesquisa,
                SkipPaginas: qtdpaginasSkip,
                QtdPorPagina: qtdPorPagina,
                Ano: ano
            }

          resultado = await this.GetData(this.metodoObterTotalJobsPaginada, params, true, null);

          return resultado;
        }

        ObterJobsAPagarPaginada = async (modoExibicaoDashboard: ModoExibicaoDashboard,
            clienteIdPesquisa: number = 0,
            qtdpaginasSkip: number = 0, 
            qtdPorPagina: number = 10, 
            ano: number
            ) => {

            let resultado: ResponseResult = {};    

            let params = {
                dashboardTipoBuscaCasting: modoExibicaoDashboard,
                ClienteIdPesquisa: clienteIdPesquisa,
                SkipPaginas: qtdpaginasSkip,
                QtdPorPagina: qtdPorPagina,
                Ano: ano
            }

          resultado = await this.GetData(this.metodoObterJobsAPagarPaginada, params, true, null);

          return resultado;
        }

        ObterJobsPagosPaginada = async (modoExibicaoDashboard: ModoExibicaoDashboard,
            clienteIdPesquisa: number = 0,
            qtdpaginasSkip: number = 0, 
            qtdPorPagina: number = 10, 
            ano: number
            ) => {

            let resultado: ResponseResult = {};    

            let params = {
                dashboardTipoBuscaCasting: modoExibicaoDashboard,
                ClienteIdPesquisa: clienteIdPesquisa,
                SkipPaginas: qtdpaginasSkip,
                QtdPorPagina: qtdPorPagina,
                Ano: ano
            }

          resultado = await this.GetData(this.metodoObterJobsPagosPaginada, params, true, null);

          return resultado;
        }

        cadastrarJob = async(Job: Jobs) => {
            let resultado: ResponseResult = {};

            resultado = await this.PostData(this.metodoCadastrar, Job, true, null);

            return resultado;
        }

        alterarJob = async(Job: Jobs) => {
            let resultado: ResponseResult = {};

            resultado = await this.PatchData(this.metodoAlterar, Job, true, null);

            return resultado;
        }

        excluirJob = async(id: number) => {
            let resultado: ResponseResult = {};

            resultado = await this.DeleteData(this.metodoExcluir, id, true, null);

            return resultado;
        }

        obterTiposJob = async() => {
            let resultado: ResponseResult = {};

            resultado = await this.GetData(this.metodoObterTiposJob, null, false, null);

            return resultado;
        }

        gerarExcelBase64Jobs = async (            
            clienteId: string = '0',
            tipoJob:number = 0,
            destinoJob:number = 0,
            mesReferencia:string = '0',
            pago: boolean = false,
            localizacao?:string) => {

            let resultado: ResponseResult = {};    

            let params = {
                ClienteId: clienteId === '' ? '0'  : clienteId,
                TipoJob: tipoJob,
                DestinoJob: destinoJob,
                Pago: pago,
                MesReferencia: mesReferencia,
                Localizacao: localizacao
            }

          resultado = await this.GetData(this.metodogerarjobsExcel, params, true, null);

          return resultado;
    }
}