import {BrowserRouter as Router} from 'react-router-dom';

import SideBar from "./components/sidebar/SideBar";
import Content from "./components/content/Content";
import { useState } from 'react';

const Routes = () => {    
    const [sidebarIsOpen, setSidebarOpen] = useState(true);
    const toggleSidebar = () => {
                                  setSidebarOpen(!sidebarIsOpen)
                                  //console.log(!sidebarIsOpen);
                                };

    return (
      <Router>
           <div className="App wrapper" style={{height: 'auto'}}>
             <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
             <Content toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen} />
           </div>
      </Router>      
    );
}

export default Routes;