import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from 'react'
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import Paginacao from '../shared/Paginacao';
import Indicacao from '../../types/Indicacao';
import IndicacaoService from '../../services/IndicacaoService';
import ResponseResult from '../../types/ResponseResult';
import Cupom from '../../types/Cupom';

const CadastroIndicacao = (param: {
                              voltarParaRegras: () => void
                           }) => {

        let indicacaoService = new IndicacaoService();

            //variáveis para paginação    
        const [totalRegistros, setTotalRegistros]  = useState(0); 
        const [clickBotaoPesquisar, setclickBotaoPesquisar ]   = useState(0);   
        let   qtdPorPagina: number = 20;
        let   qtdPaginasRodape: number = 5;

        //lista paginada
        const[listaIndicacoes, setListaIndicacoes] = useState<Indicacao[]>([])

        const [cupom, setCupom] = useState<Cupom>({
            id: 0,
            descricao: "",
            porcentagemDesconto: ""
        });

        const [formData, setFormData] = useState<Indicacao>({
            email: "",
            indicacaoEfetuouCadastroSistema: false,
            id:0,
            cupomId: 0
        })        

        const [loading, setLoading] = useState<boolean>(false);

        const [emailPesquisa, setEmailPesquisa] = useState("");

        const tableRef   = useRef<null | HTMLElement>(null)

        useEffect(() => {
            obterCupomIndicacaoAtivo();
            obterListaPaginada(0)
        }, [])
              
        const obterCupomIndicacaoAtivo = async () => {
            let response: ResponseResult = await indicacaoService.ObterCupomIndicacaoAtivo();

            if(response.status === 200)
            {
               let cupom = response.data as Cupom               
               setCupom(cupom);
            }
        }                               
                              
        const Salvar = async (e: any) => {
             e.preventDefault();
             
             if(formData.email.trim().length > 0 && emailEhValido(formData.email))
             {
                let responseCadastro = await indicacaoService.CadastrarIndicacao(formData)

                if(responseCadastro.status === 200)
                {
                    toast("Indicação cadastrada com sucesso", {
                        type: "success"
                     });

                   obterListaPaginada(0)

                   setFormData({
                     cupomId: 0,
                     email: "",
                     id: 0,
                     indicacaoEfetuouCadastroSistema : false
                   })
                }
             }
             else
             {
                toast("E-mail informado é inválido", {
                    type: "warning"
                 });
             }
        };

        const emailEhValido = (email:string) => {
            let emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi
            return emailRegex.test(email)
        }

        const clickBotaoPesquisa = (event: FormEvent) => {
            event.preventDefault();
   
            setclickBotaoPesquisar(clickBotaoPesquisar + 1);
            obterListaPaginada(0);
            obterCupomIndicacaoAtivo();
        }

        const keyPesquisa = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if(event.nativeEvent.charCode === 13)
            {           
                setclickBotaoPesquisar(clickBotaoPesquisar + 1);
                obterListaPaginada(0);
            }                  
         }

         const handleInputEmailchange = (event: ChangeEvent<HTMLInputElement>) => {
            const {value} = event.target;
            setEmailPesquisa(value);  
    
        }; 

        const obterListaPaginada = async(qtdpaginasSkip: number) => {
            
            let Indicacoes: Indicacao[] = [];
            let total:number | any = 0;  

            setLoading(true);

            let responseResult = await indicacaoService
                                       .ObterListaIndicacoesPaginada(
                                        0,
                                        emailPesquisa,
                                        false,
                                        qtdpaginasSkip,
                                        qtdPorPagina
                                        )

            if(responseResult.status === 200)
            {           
                responseResult.listaPaginada?.dataList.forEach((data: Indicacao | any) => {                
                    Indicacoes.push(data);               
                });
                 
                total = responseResult.listaPaginada?.totalPaginas;           
            }
            
            setListaIndicacoes(Indicacoes)
            setTotalRegistros(total)
            tableRef!.current?.focus();

            setLoading(false);
        }

        const handleInputchange = (event: ChangeEvent<HTMLInputElement>) => {
            const {value} = event.target;
            setFormData({
                        email: value, 
                        cupomId: cupom.id, 
                        indicacaoEfetuouCadastroSistema: false,
                        id: 0
                       });      
        };

        const handleRemoverIndicado = async(idIndicado:number, e:any) => {
              e.preventDefault();  

              let indicacao: Indicacao | any = listaIndicacoes.find(f => f.id === idIndicado);

              if(window.confirm('Deseja remover a indicação ' + indicacao.email + "?"))
              {
                let resultado = await indicacaoService.ExcluirIndicacao(idIndicado);

                if(resultado.status === 200)
                {
                  toast("Indicação removida com sucesso", {
                      type: "success"
                   });

                   obterListaPaginada(0);
                }
              }
        }
                                  
    return(
        <>
           <form method="post" 
                className="needs-validation formCadastro" 
                //onSubmit={SalvarCliente}
                autoComplete="off"
            >
                <div className="row">
                        <div className="col-lg-12">
                            <div className="card shadow-sm">
                                <div className="card-header">Cadastro de indicações</div>
                                <div className="card-body">
                                <div className="alert alert-danger" role="alert">Lembre-se de copiar e salvar o seu cupom; após o término do acesso, não será possível visualizá-lo.</div>
                                <div className="form-row">
                                        <div className="form-group col-lg-6">
                                            <label>Seu cupom de indicação:</label>
                                            <input type="text" 
                                                className="form-control text-left" 
                                                id="cupom" 
                                                name="cupom"                                                 
                                                value={cupom.descricao} 
                                                readOnly={true}                                               
                                            />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <label>Porcentagem de desconto acumulado:</label>
                                            <input type="text" 
                                                className="form-control text-left" 
                                                id="cupom" 
                                                name="cupom"                                                 
                                                value={cupom.porcentagemDesconto} 
                                                readOnly={true}                                               
                                            />
                                        </div>
                                                                           
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-lg-6">
                                            <label>Informe o e-mail da pessoa indicada:</label>
                                            <input type="mail" 
                                                className="form-control text-left" 
                                                id="mail" 
                                                name="mail" 
                                                onChange={handleInputchange}
                                                value={formData.email}                                                
                                            />
                                        </div>
                                                                           
                                    </div>
                                                                                                                                                                                                         
                                    <div className="text-left">
                                            <button className="btn btn-sm btn-outline-warning" type="button" onClick={Salvar}>
                                                Registrar indicação
                                            </button>&nbsp;
                                            <button className="btn btn-sm btn-outline-success" type="button" onClick={() => param.voltarParaRegras()}>
                                                Voltar para página de regras
                                            </button>
                                     </div>                                                                                                                  
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="text-center">
                    { loading === true && <Spinner/>}
                </div>

                <div className="row" id="tblDadosClienteCard">
                    <div className="col-lg-12">
                        <div className="card shadow-sm">
                            <div className="card-body">                             
                            <div className="form-row">
                                <div className="form-group col-lg-4">
                                                <label>Email</label>
                                                <input type="text" 
                                                    className="form-control" 
                                                    id="nome" 
                                                    name="nome" 
                                                    onChange={handleInputEmailchange} 
                                                    onKeyPress={(e) => keyPesquisa(e)}                                                                                    
                                                />
                                </div>   

                            </div>
                            <div className="text-left">
                                  <button className="btn btn-sm btn-secondary" type="button" onClick={(e) => clickBotaoPesquisa(e)} value="Pesquisar">Pesquisar</button> &nbsp;
                                  {/* <button className="btn btn-sm btn-outline-success" type="button" onClick={(e) => GerarExcelClientes(e)} value="Gerar excel">Gerar Excel</button> */}
                            </div>
                                <br/>
                                {totalRegistros > 0 &&
                                    <div className="text-right">
                                     <strong>Total de registros: {totalRegistros}</strong>
                                   </div>
                                }
                                
                                <div className="table-responsive">
                                    <table id="tblDadosCliente" className="table table-striped table-bordered table-sm w-100" ref={tableRef as any}>
                                    <thead>
                                        <tr>  
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Ações</th>
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>E-mail indicado</th>                                                                                                                    
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Situação</th>                                                                                                                                                           
                                    </tr>
                                    </thead>
                                    <tbody>                                                                                                                                                                
                                        {listaIndicacoes.map(indicacao => {
                                                    return <tr key={indicacao.id}>  
                                                              <td>
                                                                  <span style={{"cursor":"pointer"}}  
                                                                        onClick={(e:any) => handleRemoverIndicado(indicacao.id, e )}>
                                                                      <FontAwesomeIcon title='Excluir indicação' color='orange' icon={faTrash} />
                                                                  </span> 
                                                             </td>                                                              
                                                            <td>{indicacao.email}</td>                                                                                                               
                                                            <td>{indicacao.indicacaoEfetuouCadastroSistema ? "Ingressou no sistema" : "Aguardando registro"}</td>                                                                                                                                                                                                                                                                                                         
                                                        </tr>
                                        })}                                    
                                        </tbody>
                                    </table>
                                </div>

                                {
                                    totalRegistros !== undefined && 
                                    totalRegistros > 0 &&
                                          <Paginacao  
                                                   totalRegistros={totalRegistros} 
                                                   qtdPorPagina={qtdPorPagina}  
                                                   qtdPaginasRodape={qtdPaginasRodape}
                                                   buscarRegistrosPaginados={obterListaPaginada} 
                                                   reconstruirComponente={clickBotaoPesquisar}   

                                          />
                                }
                            </div>
                        </div>
                    </div>
            </div>
            </form>            
        </>
    
    )
              
}

export default CadastroIndicacao