import './App.css';

import Routes from './routes';
import Login from './pages/Autenticacao/login';

import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import UsuarioUtils from './Utils/UsuarioUtils';
import NotificacaoService from './services/NotificacaoService';
import Notificacao from './types/Notificacoes';
import JanelaModal from './pages/shared/JanelaModal';

function App() {

  const usuarioUtils =  new UsuarioUtils();
  const [usuarioLogado, setUsuarioLogado] = useState(false);   
  const [ultimaNotificacao, setUltimaNotificacao] = useState<Notificacao>()
  const [exibirNotificacao, setExibirNotificacao] = useState(false)

  useEffect(() => {   
      
    let existeToken: boolean = usuarioUtils.TokenEhValido();       
    setUsuarioLogado(existeToken);
  },[usuarioLogado])

  const onEfetuarLogin = async (logou:boolean | any) => {
       setUsuarioLogado(logou);

       if (logou) {
        const notificacaoService = new NotificacaoService();

        try {
            const response = await notificacaoService.ObterUltimaNotificacao();

            if (response.status === 200 && response.data) {
                let notificacao = response.data as Notificacao

                // Verifica se a notificação ainda não foi exibida
                const idNotificacaoExibida = localStorage.getItem('idNotificacaoExibida');
                   if (idNotificacaoExibida !== notificacao.id.toString()) {                       
                       localStorage.setItem('idNotificacaoExibida', notificacao.id.toString());
                       setUltimaNotificacao(notificacao);
                       setExibirNotificacao(true);
                   }
            }
        } catch (error) {
            // Trate os erros, se necessário
        }
    }
  }

  const renderizarTelaInicial = () => {    
    if(usuarioLogado && usuarioUtils.TokenEhValido())       
    {   
      if(usuarioUtils.UsuarioPrecisaRenovar())   
      {
        return(
          <> 
            <Login onEfetuarLogin={onEfetuarLogin} />            
            <ToastContainer/>
          </>
       )
      }
      else
      {
        return(
            <>                    
              <Routes/> 
              <ToastContainer/>

            {ultimaNotificacao && (
              <JanelaModal
                  component={
                        <div className="modal-content">
                              <div className="modal-header">
                              <h5 className="modal-title">{ultimaNotificacao.titulo}</h5>
                              <button type="button" className="close" onClick={() => setExibirNotificacao(false)}>
                                    <span aria-hidden="true">&times;</span>
                              </button>
                              </div>
                              <div className="modal-body">
                              <div className="row">
                                    <div className="col-lg-12">
                                          <div className="card">
                                          <div className="card-body">
                                                <div dangerouslySetInnerHTML={{ __html: ultimaNotificacao.textoHtml }} />
                                          </div>
                                          </div>
                                    </div>
                              </div>
                              </div>
                        </div>
                  }
                  id="modalNotificacao"
                  isOpen={exibirNotificacao}
                  onHide={() => setExibirNotificacao(false)}
                  showSaveButton={false}
                  size="xl"
                  tituloModal="Detalhes da notificação"
                  />
            )}     
                           
          </>
        )
      }
    }  
    else
    {      
       return(
         <> 
           <Login onEfetuarLogin={onEfetuarLogin} />
           <ToastContainer/>            
         </>
      )
    }
  }

  return (
    <div className="App">
       {renderizarTelaInicial()}       
    </div>
  );
}

export default App;