import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import DashboardChartPizza from "../../types/DashboardChartPizza";

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartPizza = (objPizza :{ arrayPizza:DashboardChartPizza[]}) => {

    const {arrayPizza} = objPizza;

    const getRandomNumber = (maxNum:number) => {
        return Math.floor(Math.random() * maxNum);
    };

    const getRandomColor = () => {
        const h = getRandomNumber(360);
        const s = getRandomNumber(100);
        const l = getRandomNumber(100);
      
        return `hsl(${h}deg, ${s}%, ${l}%)`;
    };

    const setBackgroundColor = () => {
        const randomColor = getRandomColor();
        return randomColor;
    }

    let labels :string[] = [];
    let quantidades: number[] = [];
    let backgroundColors: string[] = [];
    let borderColors: string[] = [];

    if(arrayPizza !== null)
    {
        arrayPizza.forEach(f => {
          labels.push(f.label)
          quantidades.push(f.qtd)

          let backcolor = setBackgroundColor();

          backgroundColors.push(backcolor)
          borderColors.push(backcolor)
        })
    }
        
    const data = {
        labels: labels,
        datasets: [
          {
            label: '% de jobs',
            data: quantidades,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
      };

      const chartOptions = {
         responsive: false
      };

    return(
        <>
        {arrayPizza != null ?
          <Doughnut
            data={data}     
            options= {chartOptions}
            height= {300}
          /> 
          : <h3 style={{"textAlign":"center"}}>Nenhum dado foi contabilizado</h3>
        }
                                                                             
        
        </>
    )
}

export default ChartPizza;