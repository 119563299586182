import { Spinner } from 'reactstrap';
import { LocalStorageUtils } from '../../Utils/LocalStorageUtils';
import StringUtils from '../../Utils/StringUtils';

const Logout = () => {
        
        let tokenUsuario = new LocalStorageUtils().obterTokenUsuario();

        if(!StringUtils.isNullOrEmpty(tokenUsuario))
        {
            LocalStorageUtils.limparDadosLocaisUsuario();      
        }
        
        setTimeout(() => {     
            window.location.href = window.location.origin;
           }, 3000); 

    return (
        <>         
           <p>Sessão expirada. redirecionando para a tela de login...<Spinner/></p>
        </>
    )
}

export default Logout;