/* eslint-disable import/no-anonymous-default-export */
import firebase from 'firebase/app'; //Original no lugar de admin
import 'firebase/firebase-auth';
import 'firebase/firestore';

import firebaseConfig from './firebase';

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();

export default {

    googleLogin: async () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        let result = await firebase.auth().signInWithPopup(provider);
        return result;
    },
    db
}