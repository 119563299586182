import Contato from "../types/Contato";
import ResponseResult from "../types/ResponseResult";
import { BaseService } from "./BaseService";

export default class CommunicationService extends BaseService 
{
    constructor(){
        super();

        this.urlCommunication = "/Communication/";
        this.methodContatoAdmin = this.urlCommunication + 'ContatoAdmin';
    }

    private urlCommunication: string;
    private methodContatoAdmin: string;

    enviarContatoAdmin = async(formContato: Contato) => {
        
      var resultado = 
        await this.PostData(this.methodContatoAdmin, formContato, true, null) as ResponseResult;
      
        return resultado;
    }
}