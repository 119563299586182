import ConfigMetas from "../types/ConfigMetas";
import ResponseResult from "../types/ResponseResult";
import { BaseService } from "./BaseService";

export default class ConfigMetasService extends BaseService
{
    constructor()
    {
        super();

        this.urlConfigMetas    = "/ConfigMetas/";
        this.CadastrarNovaMeta = this.urlConfigMetas + "Cadastrar";
        this.AlterarMeta       = this.urlConfigMetas + "Alterar";
        this.ObterLista        = this.urlConfigMetas + "ObterLista";        
    }

    private urlConfigMetas:string;
    private CadastrarNovaMeta:string;
    private AlterarMeta:string;
    private ObterLista:string;
    private resultado:ResponseResult = {};

    cadastrarMeta = async(configMeta: ConfigMetas) => {
        
        this.resultado = await this.PostData(this.CadastrarNovaMeta, configMeta, true, null);

        return this.resultado;
    }

    alterarMeta = async(configMeta: ConfigMetas) => {

        this.resultado = await this.PatchData(this.AlterarMeta, configMeta, true, null);

        return this.resultado;
    }

    obterListaConfigMeta = async() => {
        this.resultado = await this.GetData(this.ObterLista, null, true, null)
        
        return this.resultado;
    }
}