import React, { ChangeEvent, FormEvent, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-date-picker';
import { useState } from 'react';
import { Spinner } from 'reactstrap';
import Job from '../../types/jobs';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,  faTrash } from '@fortawesome/free-solid-svg-icons';
import StringUtils from '../../Utils/StringUtils';
import JobsService from '../../services/JobsService';
import ResponseResult from '../../types/ResponseResult';
import Paginacao from '../shared/Paginacao';
import { DestinoJob } from '../../types/DestinoJob';
import { TipoJob } from '../../types/TipoJob';
import ClienteService from '../../services/ClienteService';
import { CurrencyUtils } from '../../Utils/CurrencyUtils';
import CurrencyInput from 'react-currency-input-field';
import * as FileSaver from 'file-saver';

const CadastroJob: React.FC = () => {

    //serviços da api
    const jobsService: JobsService = new JobsService();
    const clienteService: ClienteService = new ClienteService();

    //Estado do formulário
    const [formData, setFormData]     = useState<Job>({  
        id: 0,        
        clienteId: 0,
        data_Prev_Pgto: null,
        dataRealizacaoJob: null,
        descricao: "",
        pago: false,   
        tipo: TipoJob.VT,      
        valor: "0",
        aprovado: true,
        destinoJob: DestinoJob.Job                         
    });
        
    const selectClienteCad = useRef<null | HTMLElement>(null)
    const tableRef         = useRef<null | HTMLElement>(null)

    //variáveis para paginação    
    const [totalRegistros, setTotalRegistros]  = useState(0); 
    const [clickBotaoPesquisar, setclickBotaoPesquisar ]   = useState(0);   
    let   qtdPorPagina: number = 30;
    let   qtdPaginasRodape: number = 5;
    
    //variávies de formulário
    const [selectTiposJob, setSelectTiposJob] = useState<any[]>([])
    const [selectClientes, setSelectClientes] = useState({label: '', value: ''});
    const [selectClientesPesquisa, setSelectClientesPesquisa] = useState({label: '', value: ''});    
    const [pagoPesquisa, setPagoPesquisa]     = useState<string>('');  
    const [mesReferenciaPesquisa, setMesReferenciaPesquisa] = useState('0');  
    const [localizacaoPesquisa, setLocalizacaoPesquisa] = useState('');    
    const [listaJobs, obterJobs]              = useState<Job[]>([]);
    const [loading, setLoading]               = useState<boolean>(false);
    const [alterarDados, setalterarDados]     = useState<boolean>(false);         

    useEffect(() => {   
        setSelectTiposJobPesquisa();              
        BuscarJobs(0);          
    },[]);    

    const setSelectTiposJobPesquisa = async () => {
        
      var responseResult: ResponseResult = await jobsService.obterTiposJob();
      var resultadoAux: any[] = [];

      if(responseResult.status === 200)
      {
          responseResult.selectList?.forEach(select => {
            resultadoAux.push({
                text: select.text,
                value: select.value
            })
          });

          setSelectTiposJob(resultadoAux);
      }

    }

    const onChangeSelectClientes = (selectedClientes: any) =>{
        setSelectClientes(selectedClientes || []);  
        
        if(selectedClientes !== null && 
           selectedClientes !== undefined &&
           selectedClientes.value !== undefined && 
           selectedClientes.value !== null)
           setFormData({...formData, ['clienteId']: selectedClientes.value});
    }

    const onChangeSelectPesquisaClientes = (selectedClientes: any) => {
        setSelectClientesPesquisa(selectedClientes || []);   
    }

    const loadOptionsSelectClientes = (inputText:any, callBack:any) => {              
       clienteService.ObterSelectItem(inputText)
                     .then(resultado => {
                        const selectListItem = resultado.selectList;
                        callBack(selectListItem!.map(i => ({label: i.text, value: i.value})))
                     });                              
    }

    const handleSelectTipoJobCad = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name , value} = event.target;
        setFormData({...formData, [name]: value});  
        console.log(name)       
        console.log(value)
    }

    const handleChangePago = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name , value} = event.target;
        setFormData({...formData, [name]: value === "Sim" ? true : false});
    }

    const handleChangePagoPesquisa = (event: ChangeEvent<HTMLSelectElement>) => {
        const {value} = event.target;
        setPagoPesquisa(value);
    }

    const handleChangeMesReferenciaPesquisa = (event: ChangeEvent<HTMLSelectElement>) => {
        const {value} = event.target;
        setMesReferenciaPesquisa(value);
    }

    const handleSelectEstadoPesquisa = (event: ChangeEvent<HTMLSelectElement>) => {
        const {value} = event.target;
        setLocalizacaoPesquisa(value)        
    }

    const handleChangeValue = async(value: string | any) => {              
        setFormData({...formData, ['valor']: value !== undefined ? value : "0"}); 
    }

    const clickBotaoPesquisa = (event: FormEvent) => {
         event.preventDefault();

         setclickBotaoPesquisar(clickBotaoPesquisar + 1);
         BuscarJobs(0)
    }

    const BuscarJobs = async (qtdpaginasSkip: number) => {
        
        let Jobs: Job[] = [];
        let total:number | any = 0; 
        let pago:boolean = pagoPesquisa === "Sim" ? true : false;       

       setLoading(true);

       let responseResult: ResponseResult = await jobsService
                                             .buscarJobsPaginado(qtdpaginasSkip, 
                                                                 qtdPorPagina, 
                                                                 selectClientesPesquisa.value,
                                                                 0,
                                                                 DestinoJob.Job,
                                                                 mesReferenciaPesquisa,
                                                                 pago,
                                                                 localizacaoPesquisa);
           
       if(responseResult.status === 200)
       {           
           responseResult.listaPaginada?.dataList.forEach((data: Job | any) => {                
               Jobs.push(data);               
           });
            
           total = responseResult.listaPaginada?.totalPaginas;           
       }       
    
       obterJobs(Jobs);
                                  
       setLoading(false);

       setTotalRegistros(total);    
       tableRef!.current?.focus();   

       handleCancelarAlteracao();
    } 

    const GerarJobsEmExcel = async (event: FormEvent) => {
        
        event.preventDefault();

        let pago:boolean = pagoPesquisa === "Sim" ? true : false;       

       setLoading(true);

       let responseResult: ResponseResult = await jobsService
                                             .gerarExcelBase64Jobs(selectClientesPesquisa.value,
                                                                    0,
                                                                    DestinoJob.Job,
                                                                    mesReferenciaPesquisa,
                                                                    pago,
                                                                    localizacaoPesquisa);
           
       if(responseResult.status === 200)
       {                      
           const base64String = (responseResult.data!.toString()) 

           const byteCharacteres = atob(base64String)
           const byteNumbers     = new Array(byteCharacteres.length)

           for(let i = 0; i < byteCharacteres.length; i++)
           {
               byteNumbers[i] = byteCharacteres.charCodeAt(i);
           }

           const byteArray = new Uint8Array(byteNumbers)

           let blob = new Blob([byteArray], {type: "application/vnd.ms-excel"})

           const file = new File([blob], 'jobs.xlsx', {type: 'application/vnd.ms-excel'});

           FileSaver.saveAs(file);

       }       
                                             
       setLoading(false);
       
       tableRef!.current?.focus();          
    } 
    
    //Cada vez que essa função é chamada no react, todo o componente é recriado
    //Com o useCallback, não será mais, pois será utilizado o que tem em memória
    const handleInputchange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name , value} = event.target;
        setFormData({...formData, [name]: value});  
    };

    const onDatePickerCadastroChange = async(event: any) => {
        setFormData({...formData, ['data_Prev_Pgto']: event});       
    }

    const onDatePickerDataJobChange = async(event: any) => {
        setFormData({...formData, ['dataRealizacaoJob']: event});       
    }

    const SalvarJob = async(event: FormEvent) => {
        event.preventDefault();

        if(!ValidarCampos(formData)) return;

        if(!alterarDados)
           await CadastrarJob();
        else
           await EditarJob();           
    }     

    const CadastrarJob = async() => {            

        let resultado: ResponseResult = await jobsService.cadastrarJob(formData);
        
        if(resultado.status === 200)
        {
            toast("Job cadastrado com sucesso", {
                type: "success"
             });

              setFormData({ id: 0,        
                            clienteId: 0,
                            data_Prev_Pgto: null,
                            dataRealizacaoJob: null,
                            descricao: "",
                            pago: false,   
                            tipo: TipoJob.VT,      
                            valor: "0",
                            aprovado: true,
                            destinoJob: DestinoJob.Job   
                        }); 

               setSelectClientes({label: '' ,  value:''}) 
            
            BuscarJobs(0); 
        }                                                      
    }

    const EditarJob = async() => {    

         let resultado: ResponseResult = await jobsService.alterarJob(formData)         

         if(resultado.status === 200)
         {
            toast("Job alterado com sucesso", {
                    type: "success"
                 });

            await BuscarJobs(0);
            
                  setFormData({ id: 0,        
                                clienteId: 0,
                                data_Prev_Pgto: null,
                                dataRealizacaoJob: null,
                                descricao: "",
                                pago: false,   
                                tipo: TipoJob.VT,      
                                valor: "0",
                                aprovado: true,
                                destinoJob: DestinoJob.Job   
                  }); 
                  
                  setSelectClientes({label: '' ,  value:''}) 

            setalterarDados(false);
         }                                
    }

    const handlePrepararEditarJob = (id: string | any, event: FormEvent) => {
        
        event.preventDefault();
        
        let Job: Job = listaJobs.find(f => f.id?.toString() === id)!;        

        if(Job)
        {
           setFormData({id: Job.id, 
                        clienteId: Job.clienteId, 
                        data_Prev_Pgto: Job.data_Prev_Pgto !== undefined && 
                                        Job.data_Prev_Pgto !== null && Job.data_Prev_Pgto !== ''
                                        ? new Date(Job.data_Prev_Pgto)
                                        : null, 
                        dataRealizacaoJob: Job.dataRealizacaoJob !== undefined && 
                                        Job.dataRealizacaoJob !== null && Job.dataRealizacaoJob !== ''
                                        ? new Date(Job.dataRealizacaoJob)
                                        : null, 
                        descricao: Job.descricao, 
                        pago: Job.pago, 
                        tipo: ConverterStringParaTipoJob(Job.tipo),
                        valor: Job.valor !== undefined && Job.valor !== null
                               ? CurrencyUtils.DecimalParaString(Job.valor)
                               : "0", 
                        aprovado: Job.aprovado,
                        destinoJob: Job.destinoJob
                       });        

                       selectClienteCad.current!.focus();  

                       setSelectClientes({label: Job.clienteNome! , 
                                          value: Job.clienteId.toString()}
                                        )                       
        }
        else
           toast("Ocorreu um erro ao buscar o job", {
                type: "error"
           }); 
           
        setalterarDados(true);
    }

    const handleCancelarAlteracao = () => {

        setFormData({ id: 0,        
            clienteId: 0,
            data_Prev_Pgto: null,
            dataRealizacaoJob: null,
            descricao: "",
            pago: false,   
            tipo: TipoJob.VT,      
            valor: "0",
            aprovado: true,
            destinoJob: DestinoJob.Job   
        });   

        setSelectClientes({label: '' ,  value:''}) 
        setalterarDados(false);        
    }

    const handleRemoverJob = async (id:string | any, event: FormEvent) => {
        
        event.preventDefault();        
    
        let Job: Job | any = listaJobs.find(f => f.id?.toString() === id);

        let resultado: ResponseResult;

        if(window.confirm('Deseja remover o job ' + Job.descricao + "?"))
        {
            resultado = await jobsService.excluirJob(Job.id); 

            if(resultado.status === 200)
            {
                toast(`Job: ${Job.descricao} removido com sucesso`,{
                    type: 'success'
                });

                BuscarJobs(0);
            }
        }

        setalterarDados(false);
    }

    const ValidarCampos = (data:Job) : boolean => {
          
        let resultado: boolean = true;

        //validar campos 

        if(StringUtils.isNullOrEmpty(data.descricao) ||
           StringUtils.isNullOrEmpty(data.valor)           
          )
        {
           toast('Os campos descrição e valor são obrigatórios', {
                type: 'warning'                
              });
                     
           resultado = false;
        }
        else if(data.pago && (!data.data_Prev_Pgto || StringUtils.isNullOrEmpty(data.valor)))
        {
            toast('Data do pagamento é obrigatória quando status for = pago', {
                type: 'warning'                
              });
                     
           resultado = false;
        }
        else if(!data.dataRealizacaoJob)
        {
            toast('Data de realização do job é obrigatória', {
                type: 'warning'                
              });
                     
           resultado = false;
        }
        else if((StringUtils.isNullOrEmpty(formData.valor) || formData.valor === '0'))
        {
            toast('O valor é obrigatório', {
                type: 'warning'                
              });
                     
           resultado = false;
        }
        else{
            if(formData.data_Prev_Pgto)
            {
                let data = new Date(formData.data_Prev_Pgto);
                 
                if(data < new Date() && !formData.pago)
                {
                    toast('O campo Pago deve ser = sim quando a data de pagamento for menor ou igual que a data atual', {
                        type: 'warning'                
                      });
                             
                   resultado = false;
                }
            }
        }

        return resultado;
    }

    const ConverterStringParaTipoJob = (tipoJob: string) :TipoJob => {
      
        let retorno: TipoJob;

        switch (tipoJob)
            {
                case "Audio Descrição" : retorno = TipoJob.AudioDescricao; break;
                case "Documentários" : retorno =  TipoJob.Documentarios; break;
                case "Dublagem": retorno = TipoJob.Dublagem ;break;
                case "E-Learning": retorno = TipoJob.ELearning;break;
                case "IA": retorno = TipoJob.IA ;break;
                case "Institucional": retorno = TipoJob.Institucional;break;
                case "Jingle": retorno = TipoJob.Jingle;break;
                case "Spot": retorno = TipoJob.Spot;break;
                case "Treinamento": retorno = TipoJob.Treinamento;break;
                case "Vídeos para internet": retorno = TipoJob.VideosInternet;break;
                case "Vinheta": retorno = TipoJob.Vinheta;break;
                case "VoiceOver": retorno = TipoJob.VoiceOver;break;
                case "Voz p/ App": retorno = TipoJob.VozApp;break;
                case "VT": retorno = TipoJob.VT ;break;
                case "Manifesto": retorno = TipoJob.Manifesto ;break;
                case "Ura": retorno = TipoJob.URA ;break;
                case "TTS": retorno = TipoJob.TTS ;break;
                case "Dublagem com sync": retorno = TipoJob.DublagemComSync ;break;
                case "Audiobook": retorno = TipoJob.Audiobook ;break;
                case "Podcast": retorno = TipoJob.Podcast ;break;
                case "Video Case": retorno = TipoJob.VideoCase ;break;
                case "Material Interno": retorno = TipoJob.MaterialInterno ;break;
                case "Monstro/Casting": retorno = TipoJob.MonstroCasting ;break;
                case "Animatic": retorno = TipoJob.Animatic ;break;
                default: retorno = TipoJob.VT;break;
            }

            return retorno;
    }

    return (
        <>        
            <form method="post" 
                className="needs-validation formCadastro"                 
                autoComplete="off"
            >
                <div className="row">
                        <div className="col-lg-12">
                            <div className="card shadow-sm">
                                <div className="card-header">Cadastro de job's</div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <div className="form-group col-lg-8">
                                            <label>Nome / empresa</label>
                                            <AsyncSelect 
                                               value={selectClientes}
                                               onChange={onChangeSelectClientes}
                                               placeholder={'Informe o cliente'}                                               
                                               loadOptions={loadOptionsSelectClientes}
                                               ref={selectClienteCad as any}
                                               id="clienteId" 
                                               name="clienteId"
                                               isClearable={true}
                                            />
                                        </div>

                                        <div className="form-group col-lg-3">
                                            <label>Tipo job</label>
                                            <select id="tipo" 
                                                   name="tipo"
                                                   onChange={handleSelectTipoJobCad}
                                                   className="form-control"
                                                   value={formData.tipo}
                                            >                                                
                                                {selectTiposJob.map(m => {
                                                   return <option value={m.value}>{m.text}</option>
                                                })}                                               
                                            </select>
                                        </div>                                       
                                    </div>
                                    <div className="form-row">
                                    <div className="form-group col-lg-5">
                                            <label>Descrição</label>
                                            <input type="text" 
                                                className="form-control text-left" 
                                                id="descricao" 
                                                name="descricao" 
                                                onChange={handleInputchange}
                                                value={formData.descricao} 
                                            />
                                        </div>                                                                                
                                        <div className="form-group col-lg-2">
                                            <label>Valor</label>
                                            <CurrencyInput
                                                id="valor"
                                                name="valor"
                                                placeholder="0,00"
                                                defaultValue={0}
                                                decimalsLimit={2}
                                                onValueChange={(value, name) => handleChangeValue(value)}
                                                prefix={"R$"}
                                                className="form-control text-left"
                                                // decimalScale={2}
                                                value={formData.valor}    
                                                min={0}                                            
                                            />
                                        </div>

                                        <div className="form-group col-lg-3">
                                                <label>Data que o job foi feito</label>
                                                <DatePicker
                                                    onChange={onDatePickerDataJobChange}
                                                    value={formData.dataRealizacaoJob}
                                                    className="form-control text-left"
                                                    // minDate={new Date()}
                                                />                                               
                                        </div> 

                                        <div className="form-group col-lg-3">
                                                <label>Pagamento</label>
                                                <DatePicker
                                                    onChange={onDatePickerCadastroChange}
                                                    value={formData.data_Prev_Pgto}
                                                    className="form-control text-left"
                                                    // minDate={new Date()}
                                                />                                               
                                        </div>                                         

                                        <div className="form-group col-lg-3">
                                              <label>Pago</label>
                                              <select 
                                                    id="pago"
                                                    name="pago"
                                                    value={formData.pago ? "Sim" : "Não"}
                                                    onChange={handleChangePago}
                                                    className="form-control"
                                                    
                                              >
                                                  <option value="Não" selected={true}>Não</option>
                                                  <option value="Sim">Sim</option>                                                  
                                              </select>
                                        </div>                                        
                                    </div>                            
                                                                                                    
                                        {alterarDados 
                                         ? 
                                          <div className="text-left">
                                              <button className="btn btn-sm btn-outline-warning" type="button" onClick={SalvarJob}>
                                              Alterar
                                              </button>
                                              &nbsp;
                                              &nbsp;
                                              <button className="btn btn-sm btn-light" type="button" onClick={handleCancelarAlteracao}>
                                              Cancelar alteração
                                             </button>
                                          </div>
                                         :
                                         <div className="text-left">
                                            <button className="btn btn-sm btn-outline-warning" type="button" onClick={SalvarJob}>
                                                Cadastrar
                                            </button>
                                         </div>
                                        }
                                                                          

                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="text-center">
                    { loading === true && <Spinner/>}
                </div>

                <div className="row" id="tblDadosClienteCard">
                    <div className="col-lg-12">
                        <div className="card shadow-sm">
                            <div className="card-body">                             
                            <div className="form-row">
                                <div className="form-group col-lg-3">
                                                <label>Nome/Empresa</label>
                                                <AsyncSelect 
                                                    value={selectClientesPesquisa}
                                                    onChange={onChangeSelectPesquisaClientes}
                                                    placeholder={'Informe o cliente'}                                               
                                                    loadOptions={loadOptionsSelectClientes}                                                    
                                                    id="clientePesquisaId" 
                                                    name="clientePesquisaId"
                                                    isClearable={true}                                                     
                                                />                                                
                                </div>  

                                <div className="form-group col-lg-2">
                                              <label>Pago</label>
                                              <select 
                                                    id="pagoPesquisa"
                                                    name="pagoPesquisa"
                                                    value={pagoPesquisa}
                                                    onChange={handleChangePagoPesquisa}
                                                    className="form-control"
                                                    
                                              >
                                                  <option value="Não" selected={true}>Não</option>
                                                  <option value="Sim">Sim</option>                                                  
                                              </select>
                                </div> 
                                <div className="form-group col-lg-3">
                                              <label>Mês que o job foi feito / Cadastrado</label>
                                              <select 
                                                    id="mesReferenciaPesquisa"
                                                    name="mesReferenciaPesquisa"
                                                    value={mesReferenciaPesquisa}
                                                    onChange={handleChangeMesReferenciaPesquisa}
                                                    className="form-control"
                                                    
                                              >
                                                  <option value="0" selected={true}>Todos</option>
                                                  <option value="1">Janeiro</option> 
                                                  <option value="2">Fevereiro</option>                                                  
                                                  <option value="3">Março</option>                                                  
                                                  <option value="4">Abril</option>                                                  
                                                  <option value="5">Maio</option>                                                  
                                                  <option value="6">Junho</option>                                                  
                                                  <option value="7">Julho</option>                                                  
                                                  <option value="8">Agosto</option>                                                  
                                                  <option value="9">Setembro</option>                                                  
                                                  <option value="10">Outubro</option>                                                  
                                                  <option value="11">Novembro</option>                                                  
                                                  <option value="12">Dezembro</option>                                                                                                   
                                              </select>
                                </div>  

                                <div className="form-group col-lg-3">
                                            <label>Localização</label>
                                            <select id="localizacaoPesquisa" 
                                                   name="localizacaoPesquisa"
                                                   onChange={handleSelectEstadoPesquisa}
                                                   className="form-control"
                                                   value={localizacaoPesquisa}
                                            >
                                                <option value="">Todos</option>
                                                <option value="AC">Acre</option>
                                                <option value="AL">Alagoas</option>
                                                <option value="AP">Amapá</option>
                                                <option value="AM">Amazonas</option>
                                                <option value="BA">Bahia</option>
                                                <option value="CE">Ceará</option>
                                                <option value="DF">Distrito Federal</option>
                                                <option value="ES">Espírito Santo</option>
                                                <option value="GO">Goiás</option>
                                                <option value="MA">Maranhão</option>
                                                <option value="MT">Mato Grosso</option>
                                                <option value="MS">Mato Grosso do Sul</option>
                                                <option value="MG">Minas Gerais</option>
                                                <option value="PA">Pará</option>
                                                <option value="PB">Paraíba</option>
                                                <option value="PR">Paraná</option>
                                                <option value="PE">Pernambuco</option>
                                                <option value="PI">Piauí</option>
                                                <option value="RJ">Rio de Janeiro</option>
                                                <option value="RN">Rio Grande do Norte</option>
                                                <option value="RS">Rio Grande do Sul</option>
                                                <option value="RO">Rondônia</option>
                                                <option value="RR">Roraima</option>
                                                <option value="SC">Santa Catarina</option>
                                                <option value="SP">São Paulo</option>
                                                <option value="SE">Sergipe</option>
                                                <option value="TO">Tocantins</option>
                                                <option value="EX">Estrangeiro</option>
                                            </select>
                                      </div>                                                                                               
                            </div>                            
                            <div className="text-left">
                                    <button className="btn btn-sm btn-secondary" type="button" onClick={(e) => clickBotaoPesquisa(e)} value="Pesquisar">Pesquisar</button> &nbsp;
                                    <button className="btn btn-sm btn-outline-success" type="button" onClick={(e) => GerarJobsEmExcel(e)} value="Gerar excel">Gerar Excel</button>
                            </div>
                                <br/>
                                {totalRegistros > 0 &&
                                    <div className="text-right">
                                     <strong>Total de registros: {totalRegistros}</strong>
                                   </div>
                                }
                                
                                <div className="table-responsive">
                                    <table id="tblDadosJob" className="table table-striped table-bordered table-sm w-100" ref={tableRef as any}>
                                    <thead>
                                        <tr>  
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Ações</th>
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Empresa</th>                                                                                                                    
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Localização</th>                                                                                 
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Tipo Job</th>
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Descrição</th>
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Data Realização Job</th>
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Valor</th>
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Data de pagamento</th>
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Pago</th>                                        
                                    </tr>
                                    </thead>
                                    <tbody>                                                                                                                                                                
                                        {listaJobs.map(job => {
                                                    return <tr key={job.id}>      
                                                            <td><a href="#" onClick={(e) => handlePrepararEditarJob(job.id?.toString(), e)}>
                                                                      <FontAwesomeIcon title='Editar job' color='gray' icon={faEdit} />
                                                                </a> 
                                                                  &nbsp;
                                                                  <a href="#" onClick={(e) => handleRemoverJob(job.id?.toString(), e )}>
                                                                      <FontAwesomeIcon title='Excluir job' color='orange' icon={faTrash} />
                                                                  </a> 
                                                           </td>                                                          
                                                            <td>{job.clienteEmpresa}</td>                                                  
                                                            <td>{job.localizacao}</td>     
                                                            <td>{job.tipo}</td>
                                                            <td>{job.descricao}</td>   
                                                            <td>{
                                                                  !job.dataRealizacaoJob !== undefined && job.dataRealizacaoJob !== null
                                                                  ?  StringUtils.FormataDataBrasileira(job.dataRealizacaoJob) 
                                                                  : ""                                                                                                                                                                   
                                                                }
                                                             </td>                                                        
                                                            <td>R$ {CurrencyUtils.DecimalParaString(CurrencyUtils.StringParaDecimal(job.valor))}</td>
                                                            <td>{
                                                                  !job.data_Prev_Pgto !== undefined && job.data_Prev_Pgto !== null
                                                                  ?  StringUtils.FormataDataBrasileira(job.data_Prev_Pgto) 
                                                                  : ""                                                                                                                                                                   
                                                                }
                                                             </td>
                                                            <td>{job.pago ? "Sim" : "Não"}</td>                                                             
                                                        </tr>
                                        })}                                    
                                        </tbody>
                                    </table>
                                </div>

                                {
                                    totalRegistros !== undefined && 
                                    totalRegistros > 0 &&
                                          <Paginacao  
                                                   totalRegistros={totalRegistros} 
                                                   qtdPorPagina={qtdPorPagina}  
                                                   qtdPaginasRodape={qtdPaginasRodape}
                                                   buscarRegistrosPaginados={BuscarJobs} 
                                                   reconstruirComponente={clickBotaoPesquisar}   

                                          />
                                }
                            </div>
                        </div>
                    </div>
            </div>
            </form>            
            
        </>
    )
}

export default CadastroJob;