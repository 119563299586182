export default interface ConfigMetas
{
    id:number;
    tipoMeta: TipoMeta;
    tipoPeriodoMeta: TipoPeriodoMeta;
    valor:string;
    ativo:boolean;    
}

export enum TipoMeta
{
    ConversaoProspectados = 1,
    ValorJobs = 2,
    CastingsAprovados = 3
}

export enum TipoPeriodoMeta
{
    Semanal = 1,
    Mensal  = 2,
    Anual   = 3
}