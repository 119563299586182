import ResponseResult from "../types/ResponseResult";
import { BaseService } from "./BaseService";
import Indicacao from "../types/Indicacao";

export default class IndicacaoService extends BaseService
{
    constructor()
    {
        super();

        this.urlIndicacao                       = "/Indicacoes/";
        this.metodoObterListaIndicacoesPaginada = this.urlIndicacao + "ObterListaIndicacoesPaginada";
        this.metodoCadastrarIndicacao           = this.urlIndicacao + "CadastrarIndicacao";
        this.metodoExcluirIndicacao             = this.urlIndicacao + "ExcluirIndicacao";
        this.metodoGerarCupomIndicacao          = this.urlIndicacao + "GerarCupomIndicacao ";
        this.metodoObterCupomIndicacaoAtivo     = this.urlIndicacao + "ObterCupomIndicacaoAtivo";
    }

    private urlIndicacao: string;
    private metodoObterListaIndicacoesPaginada: string;
    private metodoCadastrarIndicacao: string;
    private metodoExcluirIndicacao: string;
    private metodoGerarCupomIndicacao:string;
    private metodoObterCupomIndicacaoAtivo:string;

    ObterListaIndicacoesPaginada = async(id:number = 0,
                                         emailPesquisa: string,
                                         indicacaoAnterior: boolean = false,
                                         qtdpaginasSkip: number = 0, 
                                         qtdPorPagina: number = 0, ) => {

        let params = {
            id:id,
            email:emailPesquisa,
            indicacaoAnterior: indicacaoAnterior,
            skipPaginas: qtdpaginasSkip,
            qtdPorPagina: qtdPorPagina
        }                                            
     
        let resultado: ResponseResult = await this.GetData(this.metodoObterListaIndicacoesPaginada, 
                                                           params, 
                                                           true, 
                                                           null);

        return resultado;                                                           
    }

    CadastrarIndicacao = async(indicacao: Indicacao) => {
        
        let resultado: ResponseResult = {};

        resultado = await this.PostData(this.metodoCadastrarIndicacao, indicacao, true, null);

        return resultado;
    }

    ExcluirIndicacao = async(id:number) => {
        let resultado: ResponseResult = {};

        resultado = await this.DeleteData(this.metodoExcluirIndicacao, id, true, null)

        return resultado;
    }

    GerarCupomIndicacao = async() => {
        let resultado: ResponseResult = {};

        resultado = await this.PostData(this.metodoGerarCupomIndicacao, null, true,null);

        return resultado;
    }

    ObterCupomIndicacaoAtivo = async() => {
        let resultado: ResponseResult = {};

        resultado = await this.GetData(this.metodoObterCupomIndicacaoAtivo, null, true,null);

        return resultado;
    }
}