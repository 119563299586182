import classNames from "classnames";
import { Container } from "reactstrap";
import { Route, Switch, Redirect } from "react-router-dom";
import CadastroClientes from '../../pages/Clientes/Cadastro';
import CadastroProspectados from '../../pages/Prospectados/Cadastro';
import Login from '../../pages/Autenticacao/login';
import Logout from '../../pages/Autenticacao/logout';
import Dashboard from '../../pages/Dashboard';

import Topbar from "./Topbar";
import ImportarProspeccao from "../../pages/Admin/ImportarProspeccao";
import CadastroJob from "../../pages/Jobs/CadastroJob";
import UsuarioUtils from "../../Utils/UsuarioUtils";
import CadastroCasting from "../../pages/Jobs/CadastroCasting";
import MetasFC from "../../pages/Config/usuarios/Metas";
import PainelDadosUsuario from "../../pages/Autenticacao/PainelDadosUsuario";
import ClientesSemContato from "../../pages/Reports/Clientes/ClientesSemContato";
import JobsPagos from "../../pages/Reports/Jobs/Faturamento/JobsPagos";
import JobsPendentesPagamento from "../../pages/Reports/Jobs/PendentePagamento/JobsPendentesPagamento";
import ContactFC from "../../pages/Communication/Contact";
import IndicacaoPrincipal from "../../pages/Indicacoes/Principal";
import NotificacoesPrincipal from "../../pages/Notificacoes/Principal"

const usuarioUtils = new UsuarioUtils();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      usuarioUtils.TokenEhValido() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/Efetuar-LogOff" />
      )
    }
  />
);

const Content = ({ sidebarIsOpen, toggleSidebar }) => (
  <Container
    fluid
    className={classNames("content", { "is-open": sidebarIsOpen })}
  >
    <Topbar toggleSidebar={toggleSidebar} tituloMenus={sidebarIsOpen ? "Ocultar menus" : "Exibir menus"} />
    <Switch>
      <Route exact path="/Efetuar-Login" component={() => Login()} />
      {/* Menus de negócio */}
      <PrivateRoute exact path="/" component={() => Dashboard()} />
      <PrivateRoute exact path="/Gerenciar-Clientes"  component={() => CadastroClientes()} />  
      <PrivateRoute exact path="/Gerenciar-Prospectados"  component={() => CadastroProspectados()} />  
      <PrivateRoute exact path="/Efetuar-Cadastro-Job"  component={() => CadastroJob()} />   
      <PrivateRoute exact path="/Efetuar-Cadastro-Casting"  component={() => CadastroCasting()} />  
      <PrivateRoute exact path="/Efetuar-Configuracao-Metas"  component={() => MetasFC()} />  
      <PrivateRoute exact path="/Efetuar-Configuracao-Cadastro"  component={() => PainelDadosUsuario()} />
      <PrivateRoute exact path="/Indicacoes"  component={() => IndicacaoPrincipal()} />
      <PrivateRoute exact path="/Ver-Notificacoes"  component={() => NotificacoesPrincipal()} />

      {/* Relatórios: */}
      <PrivateRoute exact path="/Rel-Clientes-Sem-Contato"  component={() => ClientesSemContato()} />
      <PrivateRoute exact path="/Rel-Jobs-Pagos"  component={() => JobsPagos()} />
      <PrivateRoute exact path="/Rel-Jobs-Pendentes-Pagamento"  component={() => JobsPendentesPagamento()} />

      {/* Contato */}
      <Route exact path="/Efetuar-Contato" component={() => ContactFC()} />
      {/* Logout */}
      <Route exact path="/Efetuar-LogOff" component={() => Logout()} />
      {/* <Route exact path="/Importar-Prospeccoes" component={() => ImportarProspeccao()}/> */}
    </Switch>
  </Container>
);

export default Content;
