import React, { useState } from 'react';
import RegrasIndicacao from './Regras';
import CadastroIndicacao from './Cadastro';

const IndicacaoPrincipal: React.FC = () => {

    const[exibeRegras, setExibeRegras] = useState(true)    
    
    const voltarParaRegras = () => {
        setExibeRegras(true)
    }

    const voltarParaCadastro = () => {
        setExibeRegras(false)
    }

    return(
        <>           
           {
             exibeRegras 
             ? <RegrasIndicacao voltarParaCadastro={voltarParaCadastro}/>
             : <CadastroIndicacao voltarParaRegras={voltarParaRegras}/>
           }           
        </>        
    )

}

export default IndicacaoPrincipal;