/* eslint-disable @typescript-eslint/no-unused-vars */
import ResponseResult from '../types/ResponseResult';
import { BaseService } from './BaseService';
import LoginJwt from '../types/LoginJwt';
import Usuario from '../types/usuario';
import async from 'react-select/async';

export default class UsuarioService extends BaseService
{        
    setarLocalStorageUsuario = (response: ResponseResult) : void => {
         const loginJwt: LoginJwt | any = response;
                 
         this.localStorage.salvarTokenUsuario(loginJwt.data.accessToken);
    };

    efetuarLogin = async (Nome:string, Senha: string)  => {
        
        let usuarioLogou: boolean = false; 
        
        let params = {nome: Nome, senha: Senha};

        let responseResult: ResponseResult = await this.GetData("/usuario/Login", params, false, null);
            usuarioLogou = responseResult.status === 200;

            if(usuarioLogou)
               this.setarLocalStorageUsuario(responseResult);

        return usuarioLogou;                 
    }

    enviarCodigoResetSenha = async(email:string) => {
        let enviouCodigo: boolean = false;        

        let responseResult: ResponseResult = await this.PostData("/usuario/EnviarCodigoResetSenha", JSON.stringify(email), false, null);

        enviouCodigo =  responseResult.status === 200;

        return enviouCodigo;
    }

    resetarSenha = async(CodigoResetSenha:string, Login:string, NovaSenha:string) => {
        
        let resetouSenha: boolean = false;

        let usuarioModel = {
            Nome : Login,
            Senha : NovaSenha,
            CodigoAtivacaoResetSenha : CodigoResetSenha
        }

        let responseResult: ResponseResult = await this.PostData("/usuario/ResetarSenha", usuarioModel, true, null);

        resetouSenha = responseResult.status === 200;

        return resetouSenha;
    }

    cadastrar = async(usuario:Usuario) => {
       
         let cadastrou: boolean = false;

         let usuarioModel = {
            nome: usuario.Nome,
            senha: usuario.Senha,
            email: usuario.Email,
            cpf_cnpj: usuario.Cpf_Cnpj
         }

         let responseResult: ResponseResult = await this.PostData("/usuario/CriarUsuario", usuarioModel, false, null);

         cadastrou = responseResult.status === 200;

         if(cadastrou === true)
           this.setarLocalStorageUsuario(responseResult);

         return cadastrou;
    }

    obterDadosUsuario = async() => {
        let responseResult: ResponseResult = await this.GetData("/usuario/ObterUsuario", null, true, null);
        return responseResult.data;
    }

    salvarAlteracoesUsuario = async(usuario: Usuario) => {
        let responseResult: ResponseResult = await this.PostData("/usuario/SalvarAlteracoesUsuario", usuario, true, null);
        
        if(responseResult.status !== 200)
           return false
        else
           return true;           
    }

    obterCupomValido = async(login: string, senha: string, descricao:string) => 
    {
        let params = {
            login: login,
            senha: senha,
            descricao: descricao
        }

        let responseResult: ResponseResult = await this.GetData("/usuario/ObterCupomValidado", params, true, null);
        
        if(responseResult.status === 200)
           return responseResult.data
        else
           return null;  
    }
}
