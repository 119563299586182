import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faUsers,
  faSearch,
  faBusinessTime,
  faBriefcase,
  faArrowLeft,
  faCog,
  faBookOpen,
  faMicrophone,
  faMoneyBill,
  faMoneyCheck,
  faUserInjured,
  faHeadset,
  faAddressBook
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";

import SubMenu from "./SubMenu";

//@media only screen and (max-width: 500px)
const removeSidebarIsOpen = (toogle) => {

  var x = window.matchMedia("(max-width: 500px)")

  if(x.matches)  
     toogle();  
}

const SideBar = ({ isOpen, toggle }) => (
  
  <div id="divSideBar" className={classNames("sidebar", { "is-open": isOpen })}>
    <div className="sidebar-header mt-2">
      <span color="info" onClick={toggle} style={{ color: "#fff" }}>
        &times;
      </span>
      <h5 style={{"marginLeft":"10px"}}>LocucaoManager</h5>
      <hr></hr>
    </div>
    <div className="side-menu">
      <Nav vertical className="list-unstyled pb-3">
        <p>Menus</p>   
        {/* <NavItem onClick={toggle}> */}
        <NavItem>
          <NavLink style={{color: 'white', textDecoration: 'none'}} tag={Link} to={ "/"} onClick={() => removeSidebarIsOpen(toggle)}>
            <FontAwesomeIcon icon={faHome} className="mr-1" />
            Dashboard
          </NavLink>
        </NavItem>            

        <NavItem>
          <NavLink style={{color: 'white', textDecoration: 'none'}} tag={Link} to={"/Gerenciar-Clientes"} onClick={() => removeSidebarIsOpen(toggle)}>
            <FontAwesomeIcon icon={faUsers} className="mr-1" />
             Clientes
          </NavLink>
        </NavItem> 

        <NavItem>
          <NavLink style={{color: 'white', textDecoration: 'none'}} tag={Link} to={"/Gerenciar-Prospectados"} onClick={() => removeSidebarIsOpen(toggle)}>
            <FontAwesomeIcon icon={faSearch} className="mr-2" />
             Prospectados
          </NavLink>
        </NavItem> 

        <NavItem>
          <NavLink style={{color: 'white', textDecoration: 'none'}} tag={Link} to={"/Efetuar-Cadastro-Job"} onClick={() => removeSidebarIsOpen(toggle)}>
            <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
             Jobs
          </NavLink>
        </NavItem> 

        <NavItem>
          <NavLink style={{color: 'white', textDecoration: 'none'}} tag={Link} to={"/Efetuar-Cadastro-Casting"} onClick={() => removeSidebarIsOpen(toggle)}>
            <FontAwesomeIcon icon={faMicrophone} className="mr-2" />
             Castings
          </NavLink>
        </NavItem> 

        <NavItem>
          <NavLink style={{color: 'white', textDecoration: 'none'}} tag={Link} to={"/Indicacoes"} onClick={() => removeSidebarIsOpen(toggle)}>
            <FontAwesomeIcon icon={faUser} className="mr-2" />
             Indicações
          </NavLink>
        </NavItem> 

        <SubMenu  title="Relatórios" icon={faBookOpen} items={submenus[2]} toogleMenuPai={() => removeSidebarIsOpen(toggle)} />

        <SubMenu  title="Configurações" icon={faCog} items={submenus[1]} toogleMenuPai={() => removeSidebarIsOpen(toggle)} />

        {/* <NavItem>
          <NavLink style={{color: 'white', textDecoration: 'none'}} tag={Link} to={"/Efetuar-Configuracao-Metas"}>
            <FontAwesomeIcon icon={faBusinessTime} className="mr-2" />
            Configurações de metas
          </NavLink>
        </NavItem>  */}

        <NavItem>
          <NavLink style={{color: 'white', textDecoration: 'none'}} tag={Link} to={"/Ver-Notificacoes"} onClick={() => removeSidebarIsOpen(toggle)}>
            <FontAwesomeIcon icon={faAddressBook} className="mr-2" />
            Notificações
          </NavLink>
        </NavItem> 

       <NavItem>
          <NavLink style={{color: 'white', textDecoration: 'none'}} tag={Link} to={"/Efetuar-Contato"} onClick={() => removeSidebarIsOpen(toggle)}>
            <FontAwesomeIcon icon={faHeadset} className="mr-2" />
            Suporte
          </NavLink>
        </NavItem> 

        <NavItem>
          <NavLink style={{color: 'white', textDecoration: 'none'}} tag={Link} to={"/Efetuar-LogOff"} onClick={() => removeSidebarIsOpen(toggle)}>
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
            Sair do sistema
          </NavLink>
        </NavItem>        
        {/* <NavItem>
          <NavLink tag={Link} to={"/pages"}>
            <FontAwesomeIcon icon={faImage} className="mr-2" />
            Portfolio
          </NavLink>
        </NavItem>
         <NavItem>
          <NavLink tag={Link} to={"/faq"}>
            <FontAwesomeIcon icon={faQuestion} className="mr-2" />
            FAQ
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={"/contact"}>
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
            Contact
          </NavLink>
        </NavItem>  */}
      </Nav>
    </div>
  </div>
);

const submenus = [
  [
    {
      title: "Gerenciar Clientes",
      target: "/Gerenciar-Clientes",
    },
    {
      title: "Gerenciar Prospectados",
      target: "/Gerenciar-Prospectados",
    },
    {
      title: "Job",
      target: "/Efetuar-Cadastro-Job"
    },
    {
      title: "Casting",
      target: "/Efetuar-Cadastro-Casting",
    },
    {
      title: "Indicacoes",
      target: "/Indicacoes",
    },
    {
      title: "Configurações de metas",
      target: "/Efetuar-Configuracao-Metas",
    },
    {
      title: "Notificações",
      target: "/Ver-Notificacoes",
    }
  ],
  [
    {
      title: "Metas",
      target: "/Efetuar-Configuracao-Metas",
      icon:faBookOpen
    },
    {
      title: "Dados de cadastro",
      target: "/Efetuar-Configuracao-Cadastro",
      icon: faUser
    },
  ],
  [
    {
      title:"Clientes sem contato",
      target:"/Rel-Clientes-Sem-Contato",
      icon: faUsers
    },
    {
      title:"Jobs pagos",
      target:"/Rel-Jobs-Pagos",
      icon: faMoneyBill
    },
    {
      title:"Jobs pendentes de pagamento",
      target:"/Rel-Jobs-Pendentes-Pagamento",
      icon: faMoneyCheck
    }
  ]
 ];

export default SideBar;
