import { FormEvent, useEffect, useRef } from "react";
import { useState } from "react";
import { Spinner } from "reactstrap";
import AsyncSelect from 'react-select/async';
import ClienteService from "../../../services/ClienteService";

import ResponseResult from "../../../types/ResponseResult";
import Paginacao from "../../shared/Paginacao";
import Cliente from "../../../types/Cliente";
import { ModoExibicaoDashboard } from "../../../types/Dashboard";

/* Este componente mostra todos os prospectados cadastrados de acordo com o período (semanal, mensal e anual)*/
const TotalDeProspectados = (modoBusca:{ periodo: ModoExibicaoDashboard, modoAnoDashboard: number}) => {
    
    const tableRef   = useRef<null | HTMLElement>(null)

    const clienteService: ClienteService = new ClienteService();
    
    const [selectClientesPesquisa, setSelectClientesPesquisa] = useState({label: '', value: ''});
    const [clienteIdPesquisa, setClienteIdPesquisa] = useState<number>(0);
        
    const [listaClientes, obterClientes]   = useState<Cliente[]>([]);    
    const [loading, setLoading]    = useState<Boolean>(false);

        //variáveis para paginação    
    const [totalRegistros, setTotalRegistros]  = useState(0); 
    const [clickBotaoPesquisar, setclickBotaoPesquisar ]   = useState(0);   
    let   qtdPorPagina: number = 5;
    let   qtdPaginasRodape: number = 5;

    useEffect(() => {
        BuscarProspectados(0);        
    }, [])

    const onChangeSelectClientes = (selectedClientes: any) =>{
        setSelectClientesPesquisa(selectedClientes || []);  
        
           if(selectedClientes !== null)
             setClienteIdPesquisa(parseInt(selectedClientes.value));
           else
             setClienteIdPesquisa(0);           
    }

    const loadOptionsSelectClientes = (inputText:any, callBack:any) => {                                      
        clienteService.ObterSelectItem(inputText)
                      .then(resultado => {
                         const selectListItem = resultado.selectList;
                         callBack(selectListItem!.map(i => ({label: i.text, value: i.value})))
                      });                              
     }

     const clickBotaoPesquisa = (event: FormEvent) => {
        event.preventDefault();

        setclickBotaoPesquisar(clickBotaoPesquisar + 1);
        BuscarProspectados(0)
     }

     const BuscarProspectados = async (qtdpaginasSkip: number) => {
        
        let Clientes: Cliente[] = [];
        let total:number | any = 0; 
             
       setLoading(true);
       
       let modoBuscaSemanal: boolean = modoBusca.periodo === ModoExibicaoDashboard.semanal; 
       let modoBuscaMensal: boolean  = modoBusca.periodo === ModoExibicaoDashboard.mensal;
       let modoBuscaAnual: boolean   = modoBusca.periodo === ModoExibicaoDashboard.anual;
                
       let responseResult: ResponseResult = await clienteService
                                             .buscarClientesPaginado(clienteIdPesquisa,
                                                                     qtdpaginasSkip,
                                                                     qtdPorPagina,
                                                                     '',
                                                                     '2',
                                                                     false,
                                                                     modoBuscaSemanal,
                                                                     modoBuscaMensal,
                                                                     modoBuscaAnual,
                                                                     '',
                                                                     modoBusca.modoAnoDashboard)
           
       if(responseResult.status === 200)
       {           
           responseResult.listaPaginada?.dataList.forEach((data: Cliente | any) => {                
            Clientes.push(data);               
           });
            
           total = responseResult.listaPaginada?.totalPaginas;           
       }       
    
       obterClientes(Clientes);
                                  
       setLoading(false);

       setTotalRegistros(total);    
       tableRef!.current?.focus();          
    } 

    return(
        <>
            <form method="post" 
                className="needs-validation"                 
                autoComplete="off"
            >                
                <div className="text-center">
                    { loading === true && <Spinner />}
                </div>

                <div className="row" id="tblDadosClienteCard">
                    <div className="col-lg-12">
                        <div className="card shadow-sm">
                            <div className="card-body">                             
                            <div className="form-row">
                                <div className="form-group col-lg-8">
                                                <label>Nome/Empresa</label>
                                                <AsyncSelect 
                                                    value={selectClientesPesquisa}
                                                    onChange={onChangeSelectClientes}
                                                    placeholder={'Informe o cliente'}                                               
                                                    loadOptions={loadOptionsSelectClientes}                                                    
                                                    id="clientePesquisaId" 
                                                    name="clientePesquisaId"
                                                    isClearable={true}                                                     
                                                />                                                
                                </div>                                                                                                                                                               
                            </div>                            
                            <div className="text-left">
                                            <button className="btn btn-secondary" type="button" onClick={(e) => clickBotaoPesquisa(e)} value="Pesquisar">Pesquisar</button>
                            </div>
                                <br/>
                                {totalRegistros > 0 &&
                                    <div className="text-right">
                                     <strong>Total de registros: {totalRegistros}</strong>
                                   </div>
                                }
                                
                                <div className="table-responsive">
                                    <table id="tblDadosCliente" className="table table-striped table-bordered table-sm w-100" ref={tableRef as any}>
                                    <thead>
                                        <tr>  
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Empresa</th>                                                                                                                    
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Localização</th>                                                                                                                         
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Telefone</th>
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>E-mail</th>
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Data de cadastro</th>
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Transformado em cliente</th>
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Ultimo contato</th>
                                    </tr>
                                    </thead>
                                    <tbody>                                                                                                                                                                
                                        {listaClientes.map(cliente => {
                                                    return <tr key={cliente.id}>                                                                
                                                            <td>{cliente.empresa}</td>                                                  
                                                            <td>{cliente.localizacao}</td>                                                                 
                                                            <td>{cliente.telefone}</td>  
                                                            <td>{cliente.email}</td>  
                                                            <td>{cliente.dataCadastro}</td>
                                                            <td>{cliente.transformadoEmCliente ? "Sim" : "Não"}</td>
                                                            <td>{new Date(cliente.data_Contato).toLocaleDateString()}</td>                                                                                                                      
                                                           </tr>
                                        })}                                    
                                        </tbody>
                                    </table>
                                </div>

                                {
                                    totalRegistros !== undefined && 
                                    totalRegistros > 0 &&
                                          <Paginacao  
                                                   totalRegistros={totalRegistros} 
                                                   qtdPorPagina={qtdPorPagina}  
                                                   qtdPaginasRodape={qtdPaginasRodape}
                                                   buscarRegistrosPaginados={BuscarProspectados} 
                                                   reconstruirComponente={clickBotaoPesquisar}   

                                          />
                                }
                            </div>
                        </div>
                    </div>
            </div>
            </form>            
        </>
    )

}
export default TotalDeProspectados;