import { faEdit, faSave, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import JobsService from '../../../services/JobsService';
import Job from '../../../types/jobs';
import { CurrencyUtils } from '../../../Utils/CurrencyUtils';
import StringUtils from '../../../Utils/StringUtils';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from 'react-date-picker';

const BuildResultadoJobsAPagarTabela = (props:{ job: Job}) => {

    const {job} = props;
    
    const[novaDescricao, setNovaDescricao]           = useState(job.descricao);
    const[novoValor, setNovoValor]                   = useState(job.valor);

    const[novaDataRealizacao, setNovaDatarealizacao] = useState(!StringUtils.isNullOrEmpty(job.dataRealizacaoJob)
                                                                ? new Date(job.dataRealizacaoJob)
                                                                : null);

    const[novaDataPagamento, setNovaDataPagamento]   = useState(!StringUtils.isNullOrEmpty(job.data_Prev_Pgto)
                                                                ? new Date(job.data_Prev_Pgto)
                                                                : null);

    const[jobPago, setJobpago]                       = useState(job.pago);
    
    const[modoEdicao, setModoEdicao] = useState(false)

    useEffect(() => {                  
    }, [job])

    let jobService: JobsService = new JobsService();

    const handlePrepararEditarJob = (Edicao:boolean, event:any) => {
        event.preventDefault();
        setModoEdicao(Edicao)
        
        setNovaDescricao(job.descricao)
        setNovoValor(job.valor)
        setNovaDataPagamento(!StringUtils.isNullOrEmpty(job.data_Prev_Pgto) 
                             ? new Date(job.data_Prev_Pgto)
                             : null)
        setNovaDatarealizacao(!StringUtils.isNullOrEmpty(job.dataRealizacaoJob)
                             ? new Date(job.dataRealizacaoJob)
                             : null)
    }

    const handleMudarDescricao = (event: any) => {
        setNovaDescricao(event.target.value)
    }

    const handleMudarValor = async(value: string | any) => {              
        setNovoValor(value !== undefined ? value : "0"); 
    }

    const onDatePickerDataRealizacaoChange = async(event: any) => {
        setNovaDatarealizacao(event);       
    }

    const onDatePickerDataPagamentoChange = async(event: any) => {
        setNovaDataPagamento(event);       
    }

    const handleSelectJobPago = (event: ChangeEvent<HTMLSelectElement>) => {
        const {value} = event.target;

        console.log(value === "Sim" )
        if(value === "Sim")
          setJobpago(true)
        else
          setJobpago(false)                  
    }

    const handleCancelarEdicao = (event:any) => {
        event.preventDefault();
        setNovaDescricao(job.descricao)
        setNovoValor(job.valor)
        setNovaDataPagamento(!StringUtils.isNullOrEmpty(job.data_Prev_Pgto) 
                             ? new Date(job.data_Prev_Pgto)
                             : null)
        setNovaDatarealizacao(!StringUtils.isNullOrEmpty(job.dataRealizacaoJob)
                              ? new Date(job.dataRealizacaoJob)
                              : null)
        setModoEdicao(false)
    }

    const salvarEdicao = async (event: any) => {
        event.preventDefault();

        job.descricao         = novaDescricao;
        job.valor             = novoValor;
        job.dataRealizacaoJob = novaDataRealizacao;
        job.data_Prev_Pgto    = novaDataPagamento;
        job.pago              = jobPago;

        if(StringUtils.isNullOrEmpty(job.descricao))
        {
            toast("A descrição é obrigatória", {
                type: "warning"
             });

             return;
        }

        if(StringUtils.isNullOrEmpty(job.valor))
        {
            toast("O valor é obrigatório", {
                type: "warning"
             });

             return;
        }

        if(StringUtils.isNullOrEmpty(job.dataRealizacaoJob) && job.pago)
        {
            toast("A data de realização é obrigatória", {
                type: "warning"
             });

             return;
        }

        if(StringUtils.isNullOrEmpty(job.data_Prev_Pgto) && job.pago)
        {
            toast("A data de pagamento é obrigatória", {
                type: "warning"
             });

             return;
        }

        let data = new Date(job.data_Prev_Pgto);
                 
        if(data <= new Date() && !job.pago)
        {
            toast('O campo Pago deve ser = sim quando a data de pagamento for menor ou igual que a data atual', {
                type: 'warning'                
              });
                     
           return;
        }

        if(data > new Date() && job.pago)
        {
            toast('O campo Pago não pode ser = sim quando a data de pagamento for maior que a data atual', {
                type: 'warning'                
              });
                     
           return;
        }
        
        let resultado = await jobService.alterarJob(job);

        if(resultado.status === 200)
        {
            toast("Job alterado com sucesso", {
                type: "success"
             });

             setNovaDescricao(job.descricao)

             setModoEdicao(false)
        }        
    }

    return(
        <>
           {
               !modoEdicao ? 
                <tr key={job.id}>  
                    <td><a href="#" onClick={(e) => handlePrepararEditarJob(true, e)}>
                            <FontAwesomeIcon title='Editar job' color='gray' icon={faEdit} />
                        </a> 
                   </td>                                                              
                    <td>{job.clienteEmpresa}</td>                                                  
                    <td>{job.clienteNome}</td>                                                                 
                    <td>{job.tipo}</td>
                    <td>{job.descricao}</td>      
                    <td>{
                            !job.dataRealizacaoJob !== undefined && job.dataRealizacaoJob !== null
                            ?  StringUtils.FormataDataBrasileira(job.dataRealizacaoJob) 
                            : ""                                                                                                                                                                   
                        }
                    </td>   
                    <td>{
                            !job.data_Prev_Pgto !== undefined && job.data_Prev_Pgto !== null
                            ?  StringUtils.FormataDataBrasileira(job.data_Prev_Pgto) 
                            : ""                                                                                                                                                                   
                        }
                    </td>                                                    
                    <td>R$ {CurrencyUtils.DecimalParaString(CurrencyUtils.StringParaDecimal(job.valor))}</td>                                                                                                                      
                    <td>{ job.pago ? "Sim" : "Não"} </td>  
                </tr>
                :
                <tr key={job.id}>     
                <td><a href="#" onClick={(e) => salvarEdicao(e)}>
                            <FontAwesomeIcon title='Salvar edição' color='gray' icon={faSave} />
                     </a> 
                     &nbsp;
                     &nbsp;                     
                     <a href="#" onClick={(e) => handleCancelarEdicao(e)}>
                            <FontAwesomeIcon title='Cancelar edição' color='gray' icon={faUndo} />
                     </a> 
                   </td>                                                           
                   <td>{job.clienteEmpresa}</td>                                                  
                   <td>{job.clienteNome}</td>                                                                 
                   <td>{job.tipo}</td>
                   <td>
                      <input type="text" 
                             className="form-control text-left" 
                             id="descricao" 
                             name="descricao" 
                             onChange={handleMudarDescricao}
                             value={novaDescricao} 
                       />
                  </td>      
                   <td>
                   <DatePicker
                       onChange={onDatePickerDataRealizacaoChange}
                       value={novaDataRealizacao}
                       className="form-control text-left"                                                    
                   /> 
                  </td>   
                  <td>
                  <DatePicker
                       onChange={onDatePickerDataPagamentoChange}
                       value={novaDataPagamento}
                       className="form-control text-left"                                                    
                   /> 
                  </td>                                                    
                  <td>
                   <CurrencyInput
                         id="valor"
                         name="valor"
                         placeholder="0,00"
                         defaultValue={0}
                         decimalsLimit={2}
                         onValueChange={(value) => handleMudarValor(value)}
                         prefix={"R$"}
                         className="form-control text-left"
                         value={novoValor}    
                         min={0}                                            
                    />

                  </td>      
                  <td> 
                      <select id="Pago" 
                              name="Pago"
                              onChange={handleSelectJobPago}
                              className="form-control"
                              value={jobPago ? "Sim" : "Não"}
                       >                                        
                          <option value="Não">Não</option>                                                           
                          <option value="Sim">Sim</option>                                   
                       </select></td>                                                                                                                
                </tr>
           }
        </>
    )
    
}

export default BuildResultadoJobsAPagarTabela;