import React, { FormEvent, useEffect, useState } from 'react';
import Notificacao from '../../types/Notificacoes';
import NotificacaoService from '../../services/NotificacaoService';
import ResponseResult from '../../types/ResponseResult';
import Paginacao from '../shared/Paginacao';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faGlasses, faSearch } from '@fortawesome/free-solid-svg-icons';
import JanelaModal from '../shared/JanelaModal';
import { Spinner } from 'reactstrap';

const NotificacoesPrincipal : React.FC = () => 
{
    let notificacaoService: NotificacaoService = new NotificacaoService()

    const[listaNotificacoes, setListaNotificacoes] = useState<Notificacao[]>([])
    const[exibeModal, setExibeModal] = useState(false)
    const [notificacaoSelecionada, setNotificacaoSelecionada] = useState<Notificacao | null>(null); 

        //variáveis para paginação    
        const [totalRegistros, setTotalRegistros]  = useState(0); 
        const [clickBotaoPesquisar, setclickBotaoPesquisar ]   = useState(0);   
        let   qtdPorPagina: number = 10;
        let   qtdPaginasRodape: number = 5;

        const [loading, setLoading]  = useState<boolean>(false);
    
    //let notificacao: Notificacao = {id: 0, dataPublicacao: "", textoHtml: "", titulo: "", autorPublicacao: ""};

    const carregarNotificacoes = async(qtdpaginasSkip: number) => {
          
         let notificacoes: Notificacao[] = [];
         let total:number | any = 0;           
   
         setLoading(true);

         let responseResult: ResponseResult = await notificacaoService
                                                    .ObterPaginado(qtdpaginasSkip, qtdPorPagina);

            if(responseResult.status === 200)
            {           
            responseResult.listaPaginada?.dataList.forEach((data: Notificacao | any) => {                
               notificacoes.push(data);               
            });

            total = responseResult.listaPaginada?.totalPaginas;           
            }       

            setListaNotificacoes(notificacoes);

         setLoading(false);

         setTotalRegistros(total);  
    }

    const abrirNotificacao = (notificacaoSelecionada: Notificacao) => {
      setNotificacaoSelecionada(notificacaoSelecionada); // Armazena a notificação selecionada no estado
      setExibeModal(true);
    }

    const clickBotaoPesquisa = (event: FormEvent) => {
      event.preventDefault();

      setclickBotaoPesquisar(clickBotaoPesquisar + 1);
      carregarNotificacoes(0)
    }

    useEffect(() => {
      carregarNotificacoes(0)
    },[])

    return(
          <>             
            <div className='row' id="divExplicacaoNotificacao">
               <div className="col-lg-12">
                  <div className="card shadow-sm">
                      <div className="card-header">Central de notificações</div>
                        <div className="card-body">
                        <h2>Bem-vindo(a) à Central de Notificações</h2>
                              <p>Seja bem-vindo à tela que lista todas as notificações do sistema de locução. Aqui você encontrará todas as informações relevantes para o funcionamento do sistema e para a sua carreira de locutor.</p>
                              <p>Caso você tenha perdido alguma notificação anterior, não se preocupe! Você pode consultar todas as notificações antigas aqui. Mantenha-se atualizado e informado sobre as novidades, mudanças e oportunidades.</p>
                              <p>Este é o lugar onde você pode encontrar todas as informações importantes relacionadas ao sistema de locução e tomar decisões informadas para a sua carreira. Fique atento às notificações e aproveite ao máximo essa ferramenta.</p>
                        </div>
                  </div>
               </div>
            </div>

            <br/>
                <div className="text-center">
                    { loading === true && <Spinner/>}
            </div>

             <div className="row" id="tblDadosClienteCard">
                    <div className="col-lg-12">
                        <div className="card shadow-sm">
                            <div className="card-body">                                                        
                            <div className="text-left">
                                            <button className="btn btn-sm btn-secondary" type="button" onClick={(e) => clickBotaoPesquisa(e)} value="Pesquisar">Buscar notificações</button>
                            </div>
                                <br/>
                                {totalRegistros > 0 &&
                                    <div className="text-right">
                                     <strong>Total de registros: {totalRegistros}</strong>
                                   </div>
                                }
                                
                                <div className="table-responsive">
                                    <table id="tblDadosJob" className="table table-striped table-bordered table-sm w-100">
                                    <thead>
                                    <tr>  
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Ações</th>
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Título</th>                                                                                                                    
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Data da publicação</th>                                                                                 
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Autor</th>                                       
                                    </tr>
                                    </thead>
                                    <tbody>                                                                                                                                                                
                                        {listaNotificacoes.map(notificacao => {
                                                    return <tr key={notificacao.id}>  
                                                            <td style={{"textAlign": "center"}}><span style={{"cursor":"pointer"}} 
                                                                        onClick={() => {                                                                                                                                                                             
                                                                                         abrirNotificacao(notificacao)                                                                                                                                                                                       
                                                                                       }
                                                                                }>
                                                                     <FontAwesomeIcon title='Ver notificação detalhada' color='gray' icon={faEye} />
                                                                 </span> 
                                                             </td>                                                              
                                                            <td>{notificacao.titulo}</td>                                                  
                                                            <td>{notificacao.dataPublicacao}</td>                                                                 
                                                            <td>{notificacao.autorPublicacao}</td>                                                                                                                       
                                                        </tr>
                                        })}                                    
                                        </tbody>
                                    </table>
                                </div>

                                {
                                    totalRegistros !== undefined && 
                                    totalRegistros > 0 &&
                                    <Paginacao  
                                           totalRegistros={totalRegistros} 
                                           qtdPorPagina={qtdPorPagina}  
                                           qtdPaginasRodape={qtdPaginasRodape}
                                           buscarRegistrosPaginados={carregarNotificacoes} 
                                           reconstruirComponente={clickBotaoPesquisar}   

                                    />
                                }
                            </div>
                        </div>
                    </div>
            </div>
             
            {notificacaoSelecionada && (
              <JanelaModal
                  component={
                        <div className="modal-content">
                              <div className="modal-header">
                              <h5 className="modal-title">{notificacaoSelecionada.titulo}</h5>
                              <button type="button" className="close" onClick={() => setExibeModal(false)}>
                                    <span aria-hidden="true">&times;</span>
                              </button>
                              </div>
                              <div className="modal-body">
                              <div className="row">
                                    <div className="col-lg-12">
                                          <div className="card">
                                          <div className="card-body">
                                                <div dangerouslySetInnerHTML={{ __html: notificacaoSelecionada.textoHtml }} />
                                          </div>
                                          </div>
                                    </div>
                              </div>
                              </div>
                        </div>
                  }
                  id="modalNotificacao"
                  isOpen={exibeModal}
                  onHide={() => setExibeModal(false)}
                  showSaveButton={false}
                  size="xl"
                  tituloModal="Detalhes da notificação"
                  />
            )}                                       
          </>
    )
}

export default NotificacoesPrincipal;