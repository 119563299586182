import ResponseResult from "../types/ResponseResult";
import { BaseService } from "./BaseService";

export default class DashboardService extends BaseService
{
   constructor()
   {
       super();

       this.urlDashboard         = '/Dashboard/';
       this.metodoObterDashboard = this.urlDashboard + 'ObterDados';
       this.ObterDashTipoJobPizza = this.urlDashboard + "ObterDashTipoJobPizza";
       this.ObterDashJobPorCidadePizza = this.urlDashboard + "ObterDashJobPorCidadePizza";
   }

   private urlDashboard: string;
   private metodoObterDashboard:string;
   private ObterDashTipoJobPizza:string;
   private ObterDashJobPorCidadePizza: string;

   ObterDados = async(semanal: boolean, mensal: boolean, anual: boolean, modoAnoDashboard: number) => {

        let params = {
            PesquisaSemanal: semanal,
            PesquisaMensal: mensal,
            PesquisaAnual: anual,
            Ano: modoAnoDashboard
        }

        let resultado: ResponseResult = {}; 

        resultado = await this.GetData(this.metodoObterDashboard, params, true, null);

        return resultado;
   }

   ObterDashTipoJobsPizza = async(semanal: boolean, mensal: boolean, anual: boolean, modoAnoDashboard:number) => {

        let params = {
            PesquisaSemanal: semanal,
            PesquisaMensal: mensal,
            PesquisaAnual: anual,
            Ano: modoAnoDashboard
        }

        let resultado: ResponseResult = {}; 

        resultado = await this.GetData(this.ObterDashTipoJobPizza, params, true, null);

        return resultado;
   }

   ObterDashJobPorCidadesPizza = async(semanal: boolean, mensal: boolean, anual: boolean, modoAnoDashboard:number) => {

    let params = {
        PesquisaSemanal: semanal,
        PesquisaMensal: mensal,
        PesquisaAnual: anual,
        Ano: modoAnoDashboard
    }

    let resultado: ResponseResult = {}; 

    resultado = await this.GetData(this.ObterDashJobPorCidadePizza, params, true, null);

    return resultado;
}
}