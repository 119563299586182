export default class StringUtils{
    
    public static isNullOrEmpty(val:string | any) : boolean{
        if(val === undefined || val === null || ((typeof(val) === 'string') && val.trim()) === ''){
            return true;
        }
        return false;
    }

    public static somenteNumeros(numero: string): string{
        return numero.replace(/[^0-9]/g,'');
    }

    public static FormataDataBrasileira = (data: Date | any): string => {
        let resultado = "";    

        if(data!== "")
        {            
            resultado = new Date(data).toLocaleDateString('pt-BR', {timeZone: 'UTC'});
        }

        return resultado;
    }

    public static ConverterArquivoParaBase64 = (arquivo: Blob) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(arquivo)
            fileReader.onload = () => {
              resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
              reject(error);
            }
          })
    }
}