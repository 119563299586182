import { Bar } from 'react-chartjs-2';
import Dashboard from '../../types/Dashboard';
import { CurrencyUtils } from '../../Utils/CurrencyUtils';

const ChartAnual = (dash:{dashboard: Dashboard}) => {

  const {dashboard} = dash;  
  
  let chartData = {
      labels: [
         "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", 
         "Setembro", "Outubro", "Novembro", "Dezembro"
      ],
      datasets:[
          {
             label: 'Valores Recebidos',
             data:[
                CurrencyUtils.DecimalParaString(dashboard.janeiroConsolidado),
                CurrencyUtils.DecimalParaString(dashboard.fevereiroConsolidado),
                CurrencyUtils.DecimalParaString(dashboard.marcoConsolidado),
                CurrencyUtils.DecimalParaString(dashboard.abrilConsolidado),
                CurrencyUtils.DecimalParaString(dashboard.maioConsolidado),
                CurrencyUtils.DecimalParaString(dashboard.junhoConsolidado),
                CurrencyUtils.DecimalParaString(dashboard.julhoConsolidado),
                CurrencyUtils.DecimalParaString(dashboard.agostoConsolidado),
                CurrencyUtils.DecimalParaString(dashboard.setembroConsolidado),
                CurrencyUtils.DecimalParaString(dashboard.outubroConsolidado),
                CurrencyUtils.DecimalParaString(dashboard.novembroConsolidado),
                CurrencyUtils.DecimalParaString(dashboard.dezembroConsolidado)
             ],
             backgroundColor:[
                 'rgba(99, 255, 112, 0.76)',
                 'rgba(99, 255, 112, 0.76)',
                 'rgba(99, 255, 112, 0.76)',
                 'rgba(99, 255, 112, 0.76)',
                 'rgba(99, 255, 112, 0.76)',
                 'rgba(99, 255, 112, 0.76)',
                 'rgba(99, 255, 112, 0.76)',
                 'rgba(99, 255, 112, 0.76)',
                 'rgba(99, 255, 112, 0.76)',
                 'rgba(99, 255, 112, 0.76)',
                 'rgba(99, 255, 112, 0.76)',
                 'rgba(99, 255, 112, 0.76)'
             ]
          },
          {
             label: 'Valores a Receber',
             data:[
              CurrencyUtils.DecimalParaString(dashboard.janeiroProjetado),
              CurrencyUtils.DecimalParaString(dashboard.fevereiroProjetado),
              CurrencyUtils.DecimalParaString(dashboard.marcoProjetado),
              CurrencyUtils.DecimalParaString(dashboard.abrilProjetado),
              CurrencyUtils.DecimalParaString(dashboard.maioProjetado),
              CurrencyUtils.DecimalParaString(dashboard.junhoProjetado),
              CurrencyUtils.DecimalParaString(dashboard.julhoProjetado),
              CurrencyUtils.DecimalParaString(dashboard.agostoProjetado),
              CurrencyUtils.DecimalParaString(dashboard.setembroProjetado),
              CurrencyUtils.DecimalParaString(dashboard.outubroProjetado),
              CurrencyUtils.DecimalParaString(dashboard.novembroProjetado),
              CurrencyUtils.DecimalParaString(dashboard.dezembroProjetado)
             ],
             backgroundColor:[
                 'rgba(211, 192, 196, 0.767)',
                 'rgba(211, 192, 196, 0.767)',
                 'rgba(211, 192, 196, 0.767)',
                 'rgba(211, 192, 196, 0.767)',
                 'rgba(211, 192, 196, 0.767)',
                 'rgba(211, 192, 196, 0.767)',
                 'rgba(211, 192, 196, 0.767)',
                 'rgba(211, 192, 196, 0.767)',
                 'rgba(211, 192, 196, 0.767)',
                 'rgba(211, 192, 196, 0.767)',
                 'rgba(211, 192, 196, 0.767)',
                 'rgba(211, 192, 196, 0.767)'
             ]
          },

      ]
  };


 return(
     <>        
        <Bar
           data={chartData}
        />                
     </>
 )
}

export default ChartAnual;