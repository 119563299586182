import React, { ChangeEvent, FormEvent, useEffect, useRef } from 'react';
import DatePicker from 'react-date-picker';
import { useState } from 'react';
import { Spinner } from 'reactstrap';
import Cliente from '../../types/Cliente';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,  faTrash, faSearch } from '@fortawesome/free-solid-svg-icons';
import StringUtils from '../../Utils/StringUtils';
import ClienteService from '../../services/ClienteService';
import ResponseResult from '../../types/ResponseResult';
import Paginacao from '../shared/Paginacao';

import {
    faUser
  } from "@fortawesome/free-solid-svg-icons";
import JanelaModal from '../shared/JanelaModal';

const CadastroProspectados: React.FC = () => {

    const [montarModais, setMontarModais]   = useState(false)
    const [mostraModalObs, setMostrarModalObs] = useState(false)
    const [observacaoCliente, setObservacaoCliente] = useState<any>("")
    const [nomecliente, setNomeCliente] = useState<any>("")

    const [formData, setFormData] = useState<Cliente>({  
        id: 0,
        nome: "",
        empresa: "",
        email: "",        
        tipo_Cliente: "2",
        data_Contato: new Date(),
        localizacao: "Selecione..." ,
        observacao:""            
    });

    const txtNomeCad = useRef<null | HTMLElement>(null)
    const tableRef   = useRef<null | HTMLElement>(null)

    //variáveis para paginação    
    const [totalRegistros, setTotalRegistros]  = useState(0); 
    const [clickBotaoPesquisar, setclickBotaoPesquisar ]   = useState(0);   
    let   qtdPorPagina: number = 20;
    let   qtdPaginasRodape: number = 5;
    
    //serviço da api
    const clienteService: ClienteService = new ClienteService();

    //variávies de formulário
    const [nomePesquisa, setNomePesquisa] = useState<string>('');
    const [dataPesquisa, setDataPesquisa] = useState('');
    const [localizacaoPesquisa, setLocalizacaoPesquisa] = useState('');
    const [listaClientes, obterClientes] = useState<Cliente[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [alterarDados, setalterarDados] = useState<boolean>(false);  

    const construirModais = () => {
        if(montarModais)
        {
            return(
                <>
                   <JanelaModal
                     component={ <>
                                    <div className="row" id="tblDadosClienteCard">
                                         <div className="col-lg-12">
                                            <div className="card shadow-sm">
                                               <div className="card-body">
                                                    <div className="form-row">
                                                       <div className="form-group col-lg-12">
                                                           <label>Observação para o cliente: {nomecliente}</label>
                                                           <textarea
                                                             className="form-control text-left"
                                                             readOnly={true}
                                                             style={{"height":"100px"}}
                                                            >
                                                              {observacaoCliente}
                                                           </textarea>
                                                       </div>
                                                    </div>
                                                </div>  
                                            </div>
                                         </div>
                                    </div>
                                 </>}
                     id="modalObs"
                     isOpen={mostraModalObs}
                     onHide={() => setMostrarModalObs(false)}                     
                     showSaveButton={false}
                     size="lg"
                     tituloModal="Detalhes"
                   />

                   
                </>
            )
        }
    }

    useEffect(() => {                 
        BuscarClientes(0)    
        setMontarModais(true)            
    },[]); 

    const handleSelectEstado = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name , value} = event.target;
        setFormData({...formData, [name]: value});         
    }

    const handleSelectEstadoPesquisa = (event: ChangeEvent<HTMLSelectElement>) => {
        const {value} = event.target;
        setLocalizacaoPesquisa(value)         
    }

    const handleTextAreachange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const {name , value} = event.target;
        setFormData({...formData, [name]: value});  

    };

    const handleInputNomechange = (event: ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        setNomePesquisa(value);  

    };

    const handleDataContatoPesquisachange = (event: ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        setDataPesquisa(value);  
    };

    const keyPesquisa = (event: React.KeyboardEvent<HTMLInputElement>) => {
       if(event.nativeEvent.charCode === 13)
       {           
           setclickBotaoPesquisar(clickBotaoPesquisar + 1);
           BuscarClientes(0);
       }        
    }

    const clickBotaoPesquisa = (event: FormEvent) => {
         event.preventDefault();

         setclickBotaoPesquisar(clickBotaoPesquisar + 1);
         BuscarClientes(0)
    }

    const BuscarClientes = async (qtdpaginasSkip: number) => {
        
        let Clientes: Cliente[] = [];
        let total:number | any = 0;        

       setLoading(true);

       let responseResult: ResponseResult = await clienteService
                                             .buscarClientesPaginado(0,
                                                                     qtdpaginasSkip, 
                                                                     qtdPorPagina, 
                                                                     nomePesquisa,
                                                                     '2',
                                                                     false,
                                                                     false,
                                                                     false,
                                                                     false,
                                                                     dataPesquisa,
                                                                     new Date().getFullYear(),
                                                                     localizacaoPesquisa);
           
       if(responseResult.status === 200)
       {           
           responseResult.listaPaginada?.dataList.forEach((data: Cliente | any) => {                
               Clientes.push(data);               
           });
            
           total = responseResult.listaPaginada?.totalPaginas;           
       }       
    
       obterClientes(Clientes);
                                  
       setLoading(false);

       setTotalRegistros(total);    
       tableRef!.current?.focus();   

       handleCancelarAlteracao();
    } 
    
    const handleInputchange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name , value} = event.target;
        setFormData({...formData, [name]: value});  

    };

    const onDatePickerCadastroChange = (event: any) => {
        setFormData({...formData, ['data_Contato']: event});  
    }

    const SalvarCliente = async(event: FormEvent) => {
        event.preventDefault();

        if(!ValidarCampos(formData)) return;

        if(!alterarDados)
           await CadastrarCliente();
        else
           await EditarCliente();           
    }    

    const CadastrarCliente = async() => {
                                
        let resultado: ResponseResult = await clienteService.cadastrarCliente(formData);
        
        if(resultado.status === 200)
        {
            toast("Prospecção cadastrada com sucesso", {
                type: "success"
             });

            setFormData({id: 0, 
                         nome: '', 
                         empresa: '', 
                         telefone: '', 
                         email: '', 
                         tipo_Cliente: '2', 
                         data_Contato: null,
                         webSite: '',
                         localizacao: 'Selecione...',
                         observacao: ''
                        }); 

            BuscarClientes(0); 
        }                                                      
    }

    const EditarCliente = async() => {
        
         let resultado: ResponseResult = await clienteService.alterarCliente(formData)         

         if(resultado.status === 200)
         {
            if(formData.tipo_Cliente === '1')
            {
                toast("Prospectado transformado em cliente com sucesso", {
                    type: "success"
                 });
            }
            else
            {
                toast("Prospectado alterado com sucesso", {
                    type: "success"
                 });
            }

            await BuscarClientes(0);
             
            setFormData({id: 0, 
                         nome: '', 
                         empresa: '', 
                         telefone: '', 
                         email: '', 
                         tipo_Cliente: '2',
                         webSite: '',
                         data_Contato: null,
                         localizacao: 'Selecione...',
                         observacao: ''
                        });

            setalterarDados(false);                                    
         }                                
    }

    const handlePrepararEditarCliente = (id: string | any, event: FormEvent) => {
        
        event.preventDefault();
        
        let Cliente: Cliente = listaClientes.find(f => f.id?.toString() === id)!;

        console.log(Cliente.data_Contato);

        if(Cliente)
        {
           setFormData({id: Cliente.id, 
                        nome: Cliente.nome, 
                        empresa: Cliente.empresa, 
                        telefone: Cliente.telefone, 
                        email: Cliente.email, 
                        tipo_Cliente: Cliente.tipo_Cliente,
                        webSite: Cliente.webSite, 
                        data_Contato:  !StringUtils.isNullOrEmpty(Cliente.data_Contato)
                                        ? new Date(Cliente.data_Contato)
                                        : null,
                        localizacao: Cliente.localizacao === null ? 'Selecione...' : Cliente.localizacao,
                        observacao: Cliente.observacao
                       });        

           txtNomeCad.current!.focus();            
        }
        else
           toast("Ocorreu um erro ao buscar o prospectado", {
                type: "error"
           }); 
           
        setalterarDados(true);
    }

    const transformarEmCliente = async(event: FormEvent) => {
        
      if(window.confirm('Deseja transformar em cliente?'))
      {
        formData.tipo_Cliente = '1';
        //setFormData({...formData, ['tipo_Cliente']: '1'})
        await SalvarCliente(event);
      }
                          
    }

    const handleCancelarAlteracao = () => {

        setFormData({id: 0, 
                     nome: '', 
                     empresa: '', 
                     telefone: '', 
                     email: '', 
                     tipo_Cliente: '2',
                     localizacao: 'Selecione...',
                     webSite: '',
                     data_Contato: null,
                     observacao: ''
                    });  
        setalterarDados(false);        
    }

    const handleRemoverCliente = async (id:string | any, event: FormEvent) => {
        
        event.preventDefault();        
    
        let Cliente: Cliente | any = listaClientes.find(f => f.id?.toString() === id);

        let resultado: ResponseResult;

        if(window.confirm('Deseja remover a prospecção: ' + Cliente.nome + "?"))
        {
            resultado = await clienteService.excluirCliente(Cliente.id); 

            if(resultado.status === 200)
            {
                toast(`Prospectado: ${Cliente.nome} removido com sucesso`,{
                    type: 'success'
                });

                BuscarClientes(0);
            }
        }

        setalterarDados(false);
    }

    const ValidarCampos = (data:Cliente) : boolean => {
          
        let resultado: boolean = true;

        //validar campos 

        if(StringUtils.isNullOrEmpty(data.empresa))
        {
           toast('O campo empresa é obrigatório', {
                type: 'warning'                
              });
                     
           resultado = false;
        }

        return resultado;
    }

    return (
        <>        
            <form method="post" 
                className="needs-validation formCadastro" 
                //onSubmit={SalvarCliente}
                autoComplete="off"
            >
                <div className="row">
                        <div className="col-lg-12">
                            <div className="card shadow-sm">
                                <div className="card-header">Cadastro de Prospecções</div>
                                <div className="card-body">
                                    <div className="form-row">
                                        <div className="form-group col-lg-3">
                                            <label>Nome</label>
                                            <input type="text" 
                                                className="form-control text-left" 
                                                id="nome" 
                                                name="nome" 
                                                onChange={handleInputchange}
                                                value={formData.nome}
                                                ref={txtNomeCad as any} 
                                            />
                                        </div>
                                        <div className="form-group col-lg-3">
                                            <label>Empresa</label>
                                            <input type="text" 
                                                className="form-control text-left" 
                                                id="empresa" 
                                                name="empresa" 
                                                onChange={handleInputchange}
                                                value={formData.empresa} 
                                            />
                                        </div>
                                        <div className="form-group col-lg-3">
                                            <label>Localização</label>
                                            <select id="localizacao" 
                                                   name="localizacao"
                                                   onChange={handleSelectEstado}
                                                   className="form-control"
                                                   value={formData.localizacao}
                                            >
                                                <option value="Selecione...">Selecione...</option>
                                                <option value="AC">Acre</option>
                                                <option value="AL">Alagoas</option>
                                                <option value="AP">Amapá</option>
                                                <option value="AM">Amazonas</option>
                                                <option value="BA">Bahia</option>
                                                <option value="CE">Ceará</option>
                                                <option value="DF">Distrito Federal</option>
                                                <option value="ES">Espírito Santo</option>
                                                <option value="GO">Goiás</option>
                                                <option value="MA">Maranhão</option>
                                                <option value="MT">Mato Grosso</option>
                                                <option value="MS">Mato Grosso do Sul</option>
                                                <option value="MG">Minas Gerais</option>
                                                <option value="PA">Pará</option>
                                                <option value="PB">Paraíba</option>
                                                <option value="PR">Paraná</option>
                                                <option value="PE">Pernambuco</option>
                                                <option value="PI">Piauí</option>
                                                <option value="RJ">Rio de Janeiro</option>
                                                <option value="RN">Rio Grande do Norte</option>
                                                <option value="RS">Rio Grande do Sul</option>
                                                <option value="RO">Rondônia</option>
                                                <option value="RR">Roraima</option>
                                                <option value="SC">Santa Catarina</option>
                                                <option value="SP">São Paulo</option>
                                                <option value="SE">Sergipe</option>
                                                <option value="TO">Tocantins</option>
                                                <option value="EX">Estrangeiro</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-lg-3">
                                            <label>Telefone</label>
                                            <input type="text" 
                                                className="form-control text-left" 
                                                id="telefone" 
                                                name="telefone" 
                                                onChange={handleInputchange} 
                                                value={formData.telefone}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-lg-4">
                                                <label>E-mail</label>
                                                <input type="Email" 
                                                    className="form-control text-left" 
                                                    id="email" 
                                                    name="email" 
                                                    onChange={handleInputchange} 
                                                    value={formData.email}
                                                />
                                        </div>
                                        <div className="form-group col-lg-3">
                                                <label>Site</label>
                                                <input type="text" 
                                                    className="form-control text-left" 
                                                    id="webSite" 
                                                    name="webSite" 
                                                    onChange={handleInputchange} 
                                                    value={formData.webSite}
                                                />
                                        </div>

                                        <div className="form-group col-lg-3">
                                                <label>Ultimo contato</label>
                                                <DatePicker
                                                    onChange={onDatePickerCadastroChange}
                                                    value={formData.data_Contato}
                                                    className="form-control text-left"
                                                    maxDate={new Date()}                                                                                                        
                                                />
                                        </div> 
                                    </div>    

                                    <div className="form-row">
                                        <div className="form-group col-lg-9">
                                                    <label>Observação</label>
                                                    <textarea 
                                                        cols={12}
                                                        style={{"height":"100px"}}
                                                        onChange={handleTextAreachange}
                                                        name="observacao"
                                                        id="observacao"
                                                        value={formData.observacao}
                                                        className="form-control text-left"                                                                                                                                                              
                                                    >{formData.observacao}</textarea>
                                        </div> 
                                    </div>                          
                                                                                                    
                                        {alterarDados 
                                         ? 
                                          <div className="text-left">
                                              <button className="btn btn-sm btn-outline-warning" type="button" onClick={SalvarCliente}>
                                              Alterar
                                              </button>
                                              &nbsp;
                                              &nbsp;
                                              <button className="btn btn-sm btn-light" type="button" onClick={handleCancelarAlteracao}>
                                              Cancelar alteração
                                             </button>
                                             &nbsp;
                                             &nbsp;                                             
                                             <button className="btn btn-sm btn-secondary" type="button" onClick={transformarEmCliente}>
                                              Transformar em cliente &nbsp; <FontAwesomeIcon icon={faUser} className="mr-2" />
                                              </button>                                           
                                          </div>
                                         :
                                         <div className="text-left">
                                            <button className="btn btn-sm btn-outline-warning" type="button" onClick={SalvarCliente}>
                                                Cadastrar
                                            </button>
                                         </div>
                                        }                                                                          
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="text-center">
                    { loading === true && <Spinner/>}
                </div>

                <div className="row" id="tblDadosClienteCard">
                    <div className="col-lg-12">
                        <div className="card shadow-sm">
                            <div className="card-body">                             
                            <div className="form-row">
                                <div className="form-group col-lg-4">
                                                <label>Nome/Empresa/Email</label>
                                                <input type="text" 
                                                    className="form-control" 
                                                    id="nome" 
                                                    name="nome" 
                                                    onChange={handleInputNomechange} 
                                                    onKeyPress={(e) => keyPesquisa(e)}                                                                                    
                                                />
                                </div>  

                                <div className="form-group col-lg-3">
                                                <label>Data contato</label>
                                                <input type="date" 
                                                    className="form-control" 
                                                    id="Data_Contato" 
                                                    name="Data_Contato" 
                                                    onChange={handleDataContatoPesquisachange}
                                                    onKeyPress={(e) => keyPesquisa(e)}                                                                                      
                                                />
                                </div> 

                                <div className="form-group col-lg-3">
                                            <label>Localização</label>
                                            <select id="localizacaoPesquisa" 
                                                   name="localizacaoPesquisa"
                                                   onChange={handleSelectEstadoPesquisa}
                                                   className="form-control"
                                                   value={localizacaoPesquisa}
                                            >
                                                <option value="">Todos</option>
                                                <option value="AC">Acre</option>
                                                <option value="AL">Alagoas</option>
                                                <option value="AP">Amapá</option>
                                                <option value="AM">Amazonas</option>
                                                <option value="BA">Bahia</option>
                                                <option value="CE">Ceará</option>
                                                <option value="DF">Distrito Federal</option>
                                                <option value="ES">Espírito Santo</option>
                                                <option value="GO">Goiás</option>
                                                <option value="MA">Maranhão</option>
                                                <option value="MT">Mato Grosso</option>
                                                <option value="MS">Mato Grosso do Sul</option>
                                                <option value="MG">Minas Gerais</option>
                                                <option value="PA">Pará</option>
                                                <option value="PB">Paraíba</option>
                                                <option value="PR">Paraná</option>
                                                <option value="PE">Pernambuco</option>
                                                <option value="PI">Piauí</option>
                                                <option value="RJ">Rio de Janeiro</option>
                                                <option value="RN">Rio Grande do Norte</option>
                                                <option value="RS">Rio Grande do Sul</option>
                                                <option value="RO">Rondônia</option>
                                                <option value="RR">Roraima</option>
                                                <option value="SC">Santa Catarina</option>
                                                <option value="SP">São Paulo</option>
                                                <option value="SE">Sergipe</option>
                                                <option value="TO">Tocantins</option>
                                                <option value="EX">Estrangeiro</option>
                                            </select>
                                        </div>                                                                 
                            </div>
                            <div className="text-left">
                                            <button className="btn btn-sm btn-secondary" type="button" onClick={(e) => clickBotaoPesquisa(e)} value="Pesquisar">Pesquisar</button>
                            </div>
                                <br/>
                                {totalRegistros > 0 &&
                                    <div className="text-right">
                                     <strong>Total de registros: {totalRegistros}</strong>
                                   </div>
                                }
                                
                                <div className="table-responsive">
                                    <table id="tblDadosCliente" className="table table-striped table-bordered table-sm w-100" ref={tableRef as any}>
                                    <thead>
                                     <tr>  
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Ações</th>
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Empresa</th>                                                                                                                    
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Localização</th>                                                                                                                       
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Telefone</th>
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>E-mail</th>
                                        <th style={{position: 'sticky', top: '0', backgroundColor: 'Menu'}}>Ultimo contato</th>                                        
                                    </tr>
                                    </thead>
                                    <tbody>                                                                                                                                                                
                                        {listaClientes.map(cliente => {
                                                    return <tr key={cliente.id}>   
                                                            <td><span style={{"cursor":"pointer"}} 
                                                                        onClick={() => { 
                                                                                         if(cliente?.observacao === "")
                                                                                         {
                                                                                            toast(`Prospecto sem observação`,{
                                                                                                type: 'warning'
                                                                                            });
                                                                                            return;
                                                                                         }

                                                                                         setObservacaoCliente(cliente?.observacao?.toString())
                                                                                         setNomeCliente(cliente?.nome?.toString())
                                                                                         setMostrarModalObs(true)     
                                                                                       }
                                                                                }>
                                                                     <FontAwesomeIcon title='Observação' color='gray' icon={faSearch} />
                                                                 </span>
                                                                &nbsp;
                                                                <span style={{"cursor":"pointer"}} 
                                                                      onClick={(e) => handlePrepararEditarCliente(cliente.id?.toString(), e)}>
                                                                    <FontAwesomeIcon title='Editar prospectado' color='gray' icon={faEdit} />
                                                                </span> 
                                                                  &nbsp;
                                                                  <span style={{"cursor":"pointer"}}  
                                                                        onClick={(e) => handleRemoverCliente(cliente.id?.toString(), e )}>
                                                                    <FontAwesomeIcon title='Excluir prospectado' color='orange' icon={faTrash} />
                                                                  </span> 
                                                             </td>                                                             
                                                            <td>{cliente.empresa}</td>                                                  
                                                            <td>{cliente.localizacao}</td>                                                                                                                                                                                       
                                                            <td>{cliente.telefone}</td>
                                                            <td>{cliente.email}</td>
                                                            <td>{
                                                                  !StringUtils.isNullOrEmpty(cliente.data_Contato)
                                                                  ?  StringUtils.FormataDataBrasileira(cliente.data_Contato) 
                                                                  : ""                                                                                                                                                                   
                                                                }
                                                             </td>                                                             
                                                        </tr>
                                        })}                                    
                                        </tbody>
                                    </table>
                                </div>

                                {
                                    totalRegistros !== undefined && 
                                    totalRegistros > 0 &&
                                          <Paginacao  
                                                   totalRegistros={totalRegistros} 
                                                   qtdPorPagina={qtdPorPagina}  
                                                   qtdPaginasRodape={qtdPaginasRodape}
                                                   buscarRegistrosPaginados={BuscarClientes} 
                                                   reconstruirComponente={clickBotaoPesquisar}   

                                          />
                                }
                            </div>
                        </div>
                    </div>
            </div>
            </form>            
            {construirModais()}
        </>
    )
}

export default CadastroProspectados;