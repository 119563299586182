import { LocalStorageUtils } from "../Utils/LocalStorageUtils";
import axios, { AxiosError } from 'axios';
import ResponseResult from "../types/ResponseResult";
import { toast } from "react-toastify";


export abstract class BaseService 
{
   public localStorage = new LocalStorageUtils();        
   private UrlService = process.env.REACT_APP_PATH_SERVICES;  

   protected api = axios.create({    
      baseURL: this.UrlService,
   });

    protected ObterHeaderJson(){

        return { 'Content-Type': 'application/json'};                  
    }

    protected ObterAuthHeaderJson(){
        return {        
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${this.localStorage.obterTokenUsuario()}`            
        };
  
    }

    protected async GetData(url:string, params:any, usarToken: boolean = true, callBack: any)
    {
        let resultado: ResponseResult = {}; 
        
        let config = {
            params: params,
            headers: usarToken 
                        ? this.ObterAuthHeaderJson()
                        : this.ObterHeaderJson()
        }         

          await this.api.get(url, config)
                        .then((response: any) => {
                             resultado = this.ExtractData(response);

                             if(response.data.status !== 200)
                                this.tratarErrosNegocio(response);

                             if(callBack)
                                callBack(resultado);
                        })
                        .catch((error: AxiosError) =>
                        {                                                      
                            this.tratarErrosCatch(error);                            
                        } );

          return resultado;                        
    }

    protected async PostData(url:string, data:any, usarToken: boolean = true, callBack: any)
    {
        let resultado: ResponseResult = {};  
        
        let config = {            
            headers: usarToken 
                        ? this.ObterAuthHeaderJson()
                        : this.ObterHeaderJson()
        }                                

          await this.api.post(url, data, config)
                        .then((response: any) => {
                             resultado = this.ExtractData(response);

                             if(response.data.status !== 200)
                                this.tratarErrosNegocio(response);

                             if(callBack)
                                callBack(resultado);
                        })
                        .catch((error: AxiosError) =>
                        {                                                     
                            this.tratarErrosCatch(error);                            
                        } );

          return resultado;                        
    }

    protected async PatchData(url:string, data:any, usarToken: boolean = true, callBack: any)
    {
        let resultado: ResponseResult = {}; 
        
        let config = {            
            headers: usarToken 
                        ? this.ObterAuthHeaderJson()
                        : this.ObterHeaderJson()
        }                        

          await this.api.patch(url, data, config)
                        .then((response: any) => {
                             resultado = this.ExtractData(response);

                             if(response.data.status !== 200)
                                this.tratarErrosNegocio(response);

                             if(callBack)
                                callBack(resultado);
                        })
                        .catch((error: AxiosError) =>
                        {                                                   
                            this.tratarErrosCatch(error);                            
                        } );

          return resultado;                        
    }

    protected async DeleteData(url:string, param:any, usarToken: boolean = true, callBack: any)
    {
        let resultado: ResponseResult = {};  
        
        let config = {
            headers: usarToken 
                        ? this.ObterAuthHeaderJson()
                        : this.ObterHeaderJson()
        }           
                               
          await this.api.delete(url+"/"+param, config)
                        .then((response: any) => {
                             resultado = this.ExtractData(response);

                             if(response.data.status !== 200)
                                this.tratarErrosNegocio(response);

                             if(callBack)
                                callBack(resultado);
                        })
                        .catch((error: AxiosError) =>
                        {                                                     
                            this.tratarErrosCatch(error);                            
                        } );

          return resultado;                        
    }

    protected ExtractData(response: ResponseResult | any){
        return response.data || {};
     }

     private tratarErrosCatch = (error: AxiosError) => {

        let statusCode = error.response?.status;

        if(statusCode === 401)
        {
          toast('Sua sessão foi finalizada, redirecionando para a tela de login...', {
              type: 'warning'
          });
 
          setTimeout(() => {
            LocalStorageUtils.limparDadosLocaisUsuario();
            window.location.href = window.location.origin;
          }, 4000);
          
        }
        else
        if(statusCode === 403)
        {
           toast('Usuário sem permissão para executar essa ação', {
              type: 'warning'
           });
        }
        else
        if(statusCode === 500)
        {
            toast(`Ocorreu um erro inesperado, entre em contato com o suporte.`, {type: 'error'});
        } 
        else
        {
            toast(`Ocorreu um erro inesperado, entre em contato com o suporte.`, {type: 'error'});
        }
          
     };

     private tratarErrosNegocio(response: ResponseResult | any)
     {
         if(response)
            if(response.data.errors)
               if(response.data.errors.mensagens.length > 0)
               {
                  toast(`${response.data.errors.mensagens[0]}`, {type: 'warning'});
               }
     }
}

