import { ChangeEvent, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import CommunicationService from "../../services/CommunicationService";
import Contato from "../../types/Contato";
import StringUtils from "../../Utils/StringUtils";

const ContactFC: React.FC = () => {

    var communicationService = new CommunicationService();

   const [contato, setContato] = useState<Contato>({      
      imagem: "",
      nomeImagem: "",
      nome: "",
      texto: "",
      assunto: ""
   })

   const [loading, setLoading] = useState<Boolean>(false)

   const handleInputchange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name , value} = event.target;
    setContato({...contato, [name]: value});  
   };

   const handleTextAreachange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const {name , value} = event.target;
    setContato({...contato, [name]: value});  
   };   

   const handleInputImage = async (event: any) => {
    const {files} = event.target;
    const file = files.length > 0 ? files[0] : null;
    
    if(file !== null)
    {
        let imageIsValid = validateIsImage(file.name)

        if(!imageIsValid)
        {
            toast("Somente imagens são permitidas no formulário!", {
                type: "warning"
             });

            setContato({
                assunto: contato.assunto,
                imagem: "",
                nomeImagem: "",
                nome: contato.nome,
                texto: contato.texto
            })             

            event.target.value = null;

            return;
        }

        const base64 = await StringUtils.ConverterArquivoParaBase64(file)
        
        setContato({
            assunto: contato.assunto,
            imagem: base64 as string,
            nomeImagem: file.name,
            nome: contato.nome,
            texto: contato.texto
        }) 
    }
    else
    {
        setContato({
            assunto: contato.assunto,
            imagem: "",
            nomeImagem: "",
            nome: contato.nome,
            texto: contato.texto
        })   
    }
      
   }

   const validateIsImage = (fileName: any) =>
   {
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile =="jpg" || extFile =="jpeg" || extFile =="png"){
        return true
    }else{
        return false
    }   
   }

   const clickBotaoEnviar = async(event: any) => {
      event.preventDefault();
      
      setLoading(true)

      var responseResult = await communicationService.enviarContatoAdmin(contato);

      if(responseResult.status === 200)
      {
        toast("Formulário enviado com sucesso! Em breve a nossa equipe lhe dará o retorno.", {
            type: "success"
         });

         setContato({ 
            imagem: "",
            nomeImagem: "",
            nome: "",
            texto: "",
            assunto: ""})

         var form = document.getElementById("frmContato") as HTMLFormElement
         form.reset();
      }

      setLoading(false)
   }
   
   return(
    <>
        <form method="post" 
                className="needs-validation formCadastro"                 
                autoComplete="off"
                id="frmContato"
                name="frmContato"
            >
            <div className="row">
               <div className="col-lg-12">
                  <div className="card shadow-sm">
                      <div className="card-header">Utilize o formulário abaixo para tirar dúvidas, reportar problemas, dar feedbacks ou sugestões de funcionalidades para o sistema</div>
                        <div className="card-body">
                            <div className="form-row">                                
                                 <div className="form-group col-lg-6">
                                            <label>Informe seu nome</label>
                                            <input type="text" 
                                                className="form-control text-left" 
                                                id="nome" 
                                                name="nome" 
                                                onChange={handleInputchange}
                                                value={contato.nome}                                            
                                            />
                                 </div>
                                 <div className="form-group col-lg-6">
                                            <label>Título do assunto</label>
                                            <input type="text" 
                                                className="form-control text-left" 
                                                id="assunto" 
                                                name="assunto" 
                                                onChange={handleInputchange}
                                                value={contato.assunto}                                            
                                            />
                                 </div>
                            </div>
                         
                            <div className="form-row">
                                 <div className="form-group col-lg-8">
                                            <label>Descreva abaixo sobre o assunto</label>
                                            <textarea 
                                                cols={12}                                                
                                                style={{"height":"100px"}}
                                                onChange={handleTextAreachange}
                                                name="texto"
                                                id="texto"
                                                value={contato.texto}
                                                className="form-control text-left"                                           
                                            />
                                 </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-lg-6">
                                    <label>Se precisar exemplificar em uma imagem, anexe-a abaixo</label>
                                        <input type="file" 
                                                className="form-control text-left" 
                                                id="imagem" 
                                                name="imagem" 
                                                onChange={handleInputImage}
                                                defaultValue={contato.imagem}                                                   
                                                accept="image/png, image/gif, image/jpeg" 
                                         />
                                 </div>
                            </div>
                            
                                { loading === true                                   
                                  ? 
                                  <div className="text-left">
                                    <button className="btn btn-sm btn-secondary" disabled={true} type="button" value="Enviando">Enviando<Spinner/></button>
                                  </div>

                                  :
                                   <div className="text-left">
                                     <button className="btn btn-sm btn-secondary" type="button" onClick={(e) => clickBotaoEnviar(e)} value="Enviar">Enviar</button> &nbsp;
                                  </div>
                                }  

                            <br/>
                            <br/>
                            <br/>                                                              
                        </div>
                  </div>
               </div>
            </div>
        </form>
    </>
   )
}

export default ContactFC;