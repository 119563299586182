import jwtDecode from "jwt-decode";
import { LocalStorageUtils } from "./LocalStorageUtils";

export default class UsuarioUtils
{
     ObterParametrosUsuario = () => {
         if(this.TokenEhValido())
         {
            let token = new LocalStorageUtils().obterTokenUsuario();
            let tokenDecodificado: any = jwtDecode(token);

            return {
                DiasSemContatoClientesProspectados : tokenDecodificado["DiasSemContatoClientesProspectados"]
            }
         }
         else
             return null;
     }

     TokenEhValido = () => {
        let token = new LocalStorageUtils().obterTokenUsuario();
  
        if(token === null) return false;
  
        let tokenDecodificado: any = jwtDecode(token);
        let dataExpiracao = new Date(tokenDecodificado.iat * 1000);      
            dataExpiracao = new Date(dataExpiracao.getFullYear(), dataExpiracao.getMonth(), dataExpiracao.getDate())
        let dataHoje = new Date();
            dataHoje = new Date(dataHoje.getFullYear(), dataHoje.getMonth(), dataHoje.getDate());
          
        if(dataExpiracao < dataHoje)      
        {
           LocalStorageUtils.limparDadosLocaisUsuario();         
           return false;
        }        
        else
           return true;
    }

    UsuarioPrecisaRenovar = (): Boolean => {

        let token = new LocalStorageUtils().obterTokenUsuario();
  
        if(token === null) return false;

        let tokenDecodificado: any = jwtDecode(token);

        return tokenDecodificado["http://schemas.microsoft.com/ws/2008/06/identity/claims/expiration"].toString() === "Não"            
    }
}