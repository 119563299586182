/* eslint-disable import/no-anonymous-default-export */
export default  {
    apiKey: "AIzaSyCRoP-W9sWZQ-DMlUnHfCReCKQg9ucTlZQ",
    authDomain: "fernandalocucao.firebaseapp.com",
    projectId: "fernandalocucao",
    storageBucket: "fernandalocucao.appspot.com",
    messagingSenderId: "373916437404",
    appId: "1:373916437404:web:2d79d6b68f1bf4b27f5659",
    measurementId: "G-9YDB713KPJ"
  };

// export default {
//     apiKey: "AIzaSyCsp_OWjLQWgb9sv8tj8_WDz_zsdO1VdfI",
//     authDomain: "fernanda-locucao-dev.firebaseapp.com",
//     projectId: "fernanda-locucao-dev",
//     storageBucket: "fernanda-locucao-dev.appspot.com",
//     messagingSenderId: "657148947905",
//     appId: "1:657148947905:web:1cd26ce2ac14dcc5581340"
//   };
