import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const JanelaModal = (configuracao: {
                                    id:string,
                                    isOpen: boolean, 
                                    onHide: any, 
                                    size: string, 
                                    showSaveButton: boolean, 
                                    tituloModal: string,
                                    component:any}) => {  
                                        
    const {id, isOpen, onHide, size, showSaveButton, tituloModal, component} = configuracao;                                       

    return(
         
        <Modal 
            isOpen={isOpen} 
            onHide={onHide}
            size={size}
            id={id}
            scrollable={true}                                 
    >
        <ModalHeader closeButton>
            {tituloModal}
        </ModalHeader>
        <ModalBody>
            {component}
        </ModalBody>
        <ModalFooter>
        <Button className="btn btn-light" onClick={onHide}>
            Fechar
        </Button>

         {showSaveButton && <Button className="btn-info" onClick={onHide}>
                           Salvar
                        </Button>}

        </ModalFooter>
     </Modal>
    )

}

export default JanelaModal;