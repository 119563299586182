import React from 'react'

const RegrasIndicacao = (param: {
                            voltarParaCadastro: () => void
                        }) => {
    return(
        <>
 <div className="container mt-1">
    <h1 className="mb-4">Menu de Indicações</h1>
    <p>Olá, Locutor! Seja bem-vindo(a) ao menu de indicações do nosso sistema.</p>
    <p>Aqui, você poderá indicar seus colegas locutores para se cadastrarem no nosso sistema e, em troca,
      receberá cupons com porcentagem cumulativa para desconto em nossos serviços.</p>

    <h2 className="mt-5">Como funciona:</h2>
    <ol>
      <li>Cadastre o e-mail da pessoa que você deseja indicar e clique em "Registrar Indicação".</li>
      <li>Envie para a pessoa que você conversou e indicou o sistema a seguinte url: <a href="https://apresentacao.locucaomanager.com" target='_blank'>https://apresentacao.locucaomanager.com</a> </li>
      <li>Ao iniciar sua primeira indicação, um cupom será gerado com 0% de desconto</li>
      <li>Quando a pessoa indicada fizer o cadastro e entrar de fato no sistema, você receberá 3% de desconto de forma acumulada no cupom gerado.</li>
      <li>A porcentagem é cumulativa, ou seja, quanto mais pessoas você indicar e se cadastrarem, maior será o desconto.</li>
      <li>Você poderá acompanhar o aumento da porcentagem entrando no formulário de indicação.</li>
      <li>O desconto máximo que você poderá acumular é de 60% para cada intervalo de assinatura.</li>
      <li>Você poderá indicar no máximo até 20 pessoas para o cupom atual gerado.</li>
      <li>Ao utilizar o cupom em uma assinatura e desejar continuar indicando, outro cupom será gerado com 0% e todo o ciclo será reiniciado como explicado nos passos anteriores</li>
      <li>Não será possível indicar os mesmos e-mails que já aceitaram o convite e entraram no sistema</li>
      <li>Não será possível indicar e-mails que já foram indicados por outros locutores no sistema</li>
    </ol>

    <h2 className="mt-5">Acesse o formulário de indicação no botão abaixo:</h2>
    <form>
      <button type="submit" onClick={() => param.voltarParaCadastro() } className="btn btn-primary">Iniciar Indicação</button>
    </form>
     
     <br/>
  </div>
          <br/> 
        </>
    )

}

export default RegrasIndicacao