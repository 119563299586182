/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
//import ReCAPTCHA from "react-google-recaptcha";
import '../Autenticacao/login.css';
import useForm from '../shared/useForm';
import {Spinner} from "reactstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import {toast} from 'react-toastify';

import UsuarioService from '../../services/UsuarioService';

import StringUtils from '../../Utils/StringUtils';

import { useEffect, useState } from 'react';
import JanelaModal from "../shared/JanelaModal";
import CadastroContrato from "./CadastroContrato";
import UsuarioUtils from '../../Utils/UsuarioUtils';
import { LocalStorageUtils } from "../../Utils/LocalStorageUtils";
import CadastroUsuario from '../Autenticacao/cadastroUsuario';
//import SaibaMais from "./SaibaMais";

const Login = ({onEfetuarLogin}) => {
      
    const [{ values, loading }, handleChange, handleSubmit] = useForm();        

    const[load, setLoad] = useState(false);

    const[resetandoSenha, setResetandoSenha] = useState(false);
    const[enviandoSenha, setEnviandoSenha] = useState(false);
    
    const [showModalCadastroRenovacao, setShowModalCadastroRenovacao]  = useState(false);
    const handleCloseModalCadastroRenovacao = () =>  setShowModalCadastroRenovacao(false);

    const [showModalCadastroUsuario, setShowModalCadastroUsuario]  = useState(false);
    const handleCloseModalCadastroUsuario= () =>  setShowModalCadastroUsuario(false);

    const [saibaMaisLink, setSaibaMaisLink] = useState('')

   //  const [showModalSaibaMais, setShowModalSaibaMais]  = useState(false);
   //  const handleCloseModalSaibaMais= () =>  setShowModalSaibaMais(false);
    
    
    const usuarioService = new UsuarioService();

    const usuarioUtils =  new UsuarioUtils();
    
    const[captcha, setCaptcha] = useState('');

    const[anoAtual, setAnoAtual] = useState('');

     useEffect(() => {
         
         //Obtém link para a landing page de acordo com o ambiente
         setSaibaMaisLink(process.env.REACT_APP_SAIBAMAIS)

        //Obtém ano do copyright
         obterAno();

         //token gerado à partir da landingPage ao se cadastrar
         const query = new URLSearchParams(window.location.search);
         const token = query.get("token");
         
         if(!StringUtils.isNullOrEmpty(token))
         {            
            localStorage.setItem("locucao.token",token);
            onEfetuarLogin(true);
         }

         
     },[])
      
     const EfetuarLogin = async () => {     
           
           setLoad(true);
           
           if( StringUtils.isNullOrEmpty(values.Login)||
               StringUtils.isNullOrEmpty(values.Senha)
             )
           {
               toast('Preencha os campos', {
                            type: 'error'
                          });

               setLoad(false);
                          
               return;
           }
           
         //   if(StringUtils.isNullOrEmpty(captcha))
         //   {

         //    toast('Captcha inválido', {
         //       type: 'error'
         //     });

         //     setLoad(false);
             
         //     return;
         //   }

         let retornoLogin = await usuarioService.efetuarLogin(values.Login, values.Senha);  

         if(retornoLogin === false)
            LocalStorageUtils.limparDadosLocaisUsuario(); 
                   
         onEfetuarLogin(retornoLogin);

         setLoad(false);

         if(usuarioUtils.UsuarioPrecisaRenovar())               
            setShowModalCadastroRenovacao(true)       
         else         
            setShowModalCadastroRenovacao(false)

     }

     const iniciandoResetarSenha = async(event) => {
        event.preventDefault();

        if(values.Login === undefined || StringUtils.isNullOrEmpty(values.Login))
        {
            toast("Informe o seu login ou e-mail", {
               type:"warning"
            });

            setResetandoSenha(false)

            return;
        }
        else
        {
           setEnviandoSenha(true)
 
           let enviouCodigoEmail = await usuarioService.enviarCodigoResetSenha(values.Login);

           if(enviouCodigoEmail)
              setResetandoSenha(true)
           else           
              setResetandoSenha(false)   
              
           setEnviandoSenha(false)         
        }
     }

     const resetarSenha = async () => {

         if(StringUtils.isNullOrEmpty(values.CodigoEmail))
         {
            toast("Informe o campo código", {
               type:"warning"
            });

            return;
         }

         if(StringUtils.isNullOrEmpty(values.NovaSenha) ||
            StringUtils.isNullOrEmpty(values.ConfirmarNovaSenha)
         )
         {
            toast("Informe as senhas corretamente", {
               type:"warning"
            });
            
            return;
         }

         if(values.NovaSenha !== values.ConfirmarNovaSenha)
         {
            toast("As senhas não conferem!", {
               type:"warning"
            });
            
            return;
         }

         setLoad(true);

         let retornoResetSenha = await usuarioService.resetarSenha(values.CodigoEmail, 
                                                                   values.Login, 
                                                                   values.NovaSenha
                                                                  );
         
         if(retornoResetSenha)
         {
            toast("Senha alterada com sucesso", {
               type:"success"
            });

            setResetandoSenha(false)
         }
         else
            setResetandoSenha(true)

         setLoad(false);         
     }
     
     const onCadastroContrato = async(resultado) => {
        if(resultado.status === 200)
        {
           toast("Pagamento efetuado com sucesso",{
              type:"success"
           });
                                
           setTimeout(() => {
             window.location.reload(); 
           }, 3000);
                    
        }
     }

     const refreshModalContrato = () => {
         setShowModalCadastroRenovacao(false)
         setTimeout(() => {
            setShowModalCadastroRenovacao(true)
         }, 500);
         
     }
     
     const cancelarResetSenha = () => {
          values.Senha = "";
          values.SenhaAnterior = "";
          values.NovaSenha = "";
          values.ConfirmarNovaSenha = "";

          setResetandoSenha(false)
     }

     const onChangeCaptcha = (value) => {
        setCaptcha(value)              
     }

     const obterAno = () => {
      let data = new Date();
      let ano  = data.getFullYear();

      setAnoAtual(ano.toString())      
     }

     return (
        <>
            <section className="login-block">
                <div className="container">
                    <div className="row">
                       <div className="col-md-4 login-sec">
                           <h2 className="text-center">Entrar no sistema</h2>
                            
                              {!resetandoSenha ?
                                  <form className="login-form" onSubmit={handleSubmit(EfetuarLogin)}>
                                    <div className="form-group">
                                    <label for="exampleInputEmail1" className="text-uppercase">Login ou E-mail</label>
                                       <input type="text" 
                                              className="form-control" 
                                              placeholder=""                                               
                                              name="Login" 
                                              id="Login"
                                              onChange={handleChange}
                                       />
                                    </div>
                                    <div className="form-group">
                                       <label for="exampleInputPassword1" className="text-uppercase">Senha</label>
                                       <input type="password" 
                                              className="form-control" 
                                              placeholder="" 
                                              name="Senha" 
                                              id="Senha"
                                              onChange={handleChange}
                                       />
                                    </div>
                                    {/* <div className="form-check">
                                    <label className="form-check-label">
                                       <input type="checkbox" className="form-check-input"/>
                                       <small>Lembre-me</small>
                                    </label> */}
                                    {load ? <button 
                                             className="btn btn-login float-left"
                                             >Entrando...<Spinner/>
                                            </button>
                                    :                                           
                                          <button type="submit" 
                                                  className="btn btn-login float-left"
                                              >Entrar
                                          </button>
                                    }    
                                    {enviandoSenha
                                      ? <i style={{"float": "right"}}>Enviando código para seu e-mail...<Spinner/></i>                            
                                      : <a style={{"float": "right"}} onClick={iniciandoResetarSenha} href="#">Esqueci a senha</a>                            
                                    }                                 
                                  {/* </div> */}
                               </form>
                               :
                               <form onSubmit={handleSubmit(resetarSenha)}>
                                   <div className="form-group">
                                    <label for="Login">Login</label>
                                       <input type="text" 
                                              className="form-control" 
                                              placeholder=""                                               
                                              name="LoginReset" 
                                              id="LoginReset"                                              
                                              value={values.Login}
                                              readOnly={true}
                                       />
                                    </div>
                                    <div className="form-group">
                                    <strong>Um código de verificação foi enviado para o seu e-mail, insira-o abaixo:</strong><br/>
                                    <label for="CodigoEmail">Código recebido no e-mail</label>
                                       <input type="text" 
                                              className="form-control" 
                                              placeholder=""                                               
                                              name="CodigoEmail" 
                                              id="CodigoEmail"                                                                                            
                                              onChange={handleChange}
                                       />
                                    </div>
                                    <div className="form-group">
                                       <label for="NovaSenha">Nova Senha</label>
                                       <input type="password" 
                                              className="form-control"                                               
                                              name="NovaSenha" 
                                              id="NovaSenha"
                                              onChange={handleChange}
                                       />
                                    </div>
                                    <div className="form-group">
                                       <label for="ConfirmarNovaSenha">Confirmar Nova Senha</label>
                                       <input type="password" 
                                              className="form-control"                                               
                                              name="ConfirmarNovaSenha" 
                                              id="ConfirmarNovaSenha"
                                              onChange={handleChange}
                                       />
                                    </div>
                                     { load ? <button className="btn btn-light">Resetando...<Spinner/></button>  
                                           :  <button className="btn btn-light" type="submit">Alterar a senha</button>   
                                     }  
                                      <a style={{"float": "right"}} onClick={cancelarResetSenha} href="#">Cancelar</a>   
                               </form>
                              }                                                                                                                                                           
                       </div>
                       <div className="col-md-8 banner-sec">
                         <div className="banner-text">
                            <h2 id="tituloPlataforma"><strong>Plataforma para gerenciamento de carreira em locução</strong></h2>
                            <p>                              
                                 Através desta plataforma, você poderá cadastrar os seus  
                                 clientes, prospecções, castings, jobs e obter suas métricas.<br/>
                                 Além de tudo isso, poderá acompanhar a evolução de seus trabalhos através de um dashboard, 
                                 onde mostrará jobs que foram pagos, jobs pendentes de pagamento, 
                                 taxa de conversão de prospectados em clientes e muito mais!<br/>
                                 Cadastre-se agora e experimente esta ferramenta que com certeza irá lhe auxiliar 
                                 em seu dia a dia.                                                                                       
                           </p>  
                           {/* <div className="btn-group" role="group" aria-label="one group">
                              <button className="btn btn-cadastro"
                                    onClick={() => setShowModalCadastroUsuario(true)}
                              >Cadastre-se</button>
                           </div>                                                  */}
                           <div className="btn-group" role="group" aria-label="second group">
                              <a href={saibaMaisLink}                               
                                className="btn btn-saibamais"
                              >Saiba Mais!</a>
                           </div>
                                                     
                         </div>
                       </div>
                    </div>
                    <div className="row">
                      <div className="footer-copyright text-center py-2">
                        © {anoAtual} Copyright: <a href='/'>LocucaoManager</a><br/> 
                        Suporte: <a href='mailto:suporte@locucaomanager.com'> suporte@locucaomanager.com </a>
                     </div> 
                    </div>
                </div>       

        
         </section>

              <JanelaModal
                  id="modalCadastroContrato"
                  isOpen={showModalCadastroRenovacao}
                  onHide={handleCloseModalCadastroRenovacao}
                  showSaveButton={false}
                  size="lg"
                  tituloModal="Selecione um plano:"
                  component={<CadastroContrato 
                              precisaRenovar={true} 
                              nomeContratante={values.Login} 
                              senhaContratante={values.Senha}
                              onCadastroContrato={onCadastroContrato}
                              onRefresh={refreshModalContrato}
                             />
                            }
               />

            <JanelaModal
               id="modalCadastroUsuario"
               isOpen={showModalCadastroUsuario}
               onHide={handleCloseModalCadastroUsuario}
               showSaveButton={false}
               size="xl"
               tituloModal="Cadastre-se para ter acesso ao sistema"
               component={<CadastroUsuario/> }
            />

             {/* <JanelaModal
               id="modalSaibaMais"
               isOpen={showModalSaibaMais}
               onHide={handleCloseModalSaibaMais}
               showSaveButton={false}
               size="lg"
               tituloModal="Saiba mais sobre a plataforma:"
               component={<SaibaMais/> }
            /> */}
        </>
     );
}

export default Login;